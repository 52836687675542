// @flow

export type tagInputStringRepresentationType = string;

export const tagInputNodeEnum = {
  string: 0,
  tag: 1
};

export type tagInputNodeEnumType = $Values<typeof tagInputNodeEnum>;

export const cursorUpdateEventEnum = {
  unknown: 0,
  focus: 1,
  keyUp: 2,
  tagClick: 3,
  tagDelete: 4,
  dropdownTrigger: 5,
  textDelete: 6,
  textInput: 7
};

export type cursorUpdateEventEnumType = $Values<typeof cursorUpdateEventEnum>;

export type tagInputNodeType = {|
  type: tagInputNodeEnumType,
  content: string
|};

export type tagInputNodesType = Array<tagInputNodeType>;

export type tagInputTagType = {|
  id: string,
  content: string
|};

export type tagInputTagMapType = {
  [string]: tagInputTagType
};

export type tagInputRenderTagContentMapType = {
  [string]: string
};

export type dropdownTriggerDetectorStateEnumType = $Values<
  typeof dropdownTriggerDetectorStateEnum
>;

export const dropdownTriggerDetectorStateEnum = {
  waitingForTrigger: 0,
  showDropdown: 1,
  dropdownActive: 2,
  closeDropdown: 3
};

//@flow
import { QuestionTypeEnum } from "../../../Pages/QuestionTypes";
import { operation } from "../../../Pages/WispformTypings";
import BaseFormConfig from "./BaseFormConfig";
import { FinalPageType, PreviewListSection } from "./FormConfigEnums";
import { IFormConfig } from "./IFormConfig";

export default class OutcomeQuizConfig
  extends BaseFormConfig
  implements IFormConfig
{
  displayName = "Calculator";
  availableQuestionOperations = [];
  previewListSection = [
    PreviewListSection.QUESTION,
    PreviewListSection.OUTCOME
  ];
  finalPageType = FinalPageType.OUTCOMEPAGE;
  notSupportedContentTypes = [QuestionTypeEnum.ThankyouPage];
  creationModalConfig = {
    title: "Create a Calculator Form",
    inputPlaceholder: "new calculator",
    description: `To begin creating a calculator, please first give it a name 😊`,
    enableTemplate: false
  };
}

//@flow
import ToggleWrapper, { Toggle } from "../ToggleWrapper";
import * as React from "react";
import Question_Types, {
  QuestionTypeEnum,
  type questionType
} from "../../../QuestionTypes";
import "./style.css";
import {
  UICoreBox,
  UICoreText,
  UICoreInput,
  UICoreTooltip,
  UICoreDivider
} from "../../../../Component/UICore";
import UploadMediaButton from "./UploadMediaButton";
import { type thankyouPageType, operation } from "../../../WispformTypings";
import JumpLogicContainer from "./JumpLogicContainer";
import DynamicPricing from "./DynamicPricing";
import ScoreButton from "./ScoreButton";
import ScalerSteps from "./ScalerSteps";
import MaxWordLimit from "./MaxWordLimit";
import DateFormatDropdown from "./DateFormatDropdown";
import CurrencyDropdown from "./CurrencyDropdown";
import ImageCompressionButton from "./ImageCompressionButton";
import MaxOutcome2DisplayDropdown from "./MaxOutcome2DisplayDropdown";
import OutcomeMapping from "./OutcomeMapping";
import ScalerUnit from "./ScalerUnit";
import { thankyouPage } from "../../States/reducer";
import {
  toArray,
  replace,
  toNumber,
  nullSafe,
  isEmptyArray
} from "../../../../Library/Util";
import { isPaidUser } from "../../../../Library/UserInfo";
import ThankyouPageDisplayLogic from "./ThankyouPageDisplayLogic";
import {
  type questionMediaTypeEnumType,
  type questionMediaType
} from "../../../../FlowTypes/questionTypes";
import { hasMediaSpec } from "../../../../Component/QuestionMediaRender";
import AvailableSubscriptionPlans from "./AvailableSubscriptionPlans";
import SocialShare from "./SocialShare";
import WithFormConfig, {
  type WithFormConfigInjectedProps
} from "../../../../Helper_HOC/WithFormConfig";
import { type TGenericContentConfig } from "../../../../Component/GenericFormContent/type";
import OutcomeLayout from "./OutcomeLayout";
type Props = {|
  ...WithFormConfigInjectedProps,
  question: questionType,
  questionIndex: number | string,
  thankyouPageIndex: number,
  thankyouPages: Array<thankyouPageType>,
  update_question_config: (string, boolean, number) => void,
  update_question_media: (?questionMediaType, number | string) => void,
  update_thankyou_page: (pages: Array<thankyouPageType>) => void,
  update_outcome_page: (outcomePage: TGenericContentConfig) => void,
  outcomePageConfig: ?TGenericContentConfig,
  updateQuestionContent: (number, any, ?boolean) => void,
  hasScoreSetting: boolean
|};

type State = {||};
class QuestionSettings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  //$FlowFixMe
  types = new Question_Types().types;

  _getCurrentQuestionOperations = () => {
    if (this.props.questionIndex === QuestionTypeEnum.OutcomePage) {
      return this.types[QuestionTypeEnum.OutcomePage].operations;
    } else {
      return toArray(
        this.props.question &&
          this.types[this.props.question.type] &&
          this.types[this.props.question.type].operations
      );
    }
  };

  _getQuestionContents = () => {
    return this.props.question && this.props.question.contents;
  };

  _canSetOutcomeMapping = () =>
    this.props.formConfig &&
    this.props.formConfig.availableQuestionOperations.includes(
      operation.outcomeMapping
    ) &&
    this._getCurrentQuestionOperations().includes(operation.outcomeMapping);

  _canAddImage = () =>
    this._getCurrentQuestionOperations().includes(operation.image);

  _canAddJumpLogic = () =>
    this._getCurrentQuestionOperations().includes(operation.jumpLogic);

  _canAddScore = () =>
    this._getCurrentQuestionOperations().includes(operation.score);

  _canAdjustScalerSteps = () =>
    this._getCurrentQuestionOperations().includes(operation.scalerSteps);

  _canSetAvailableSubscriptionPlan = () =>
    this._getCurrentQuestionOperations().includes(
      operation.availableSubscriptionPlans
    );

  _canImageCompress = () =>
    isPaidUser() &&
    this._getCurrentQuestionOperations().includes(operation.imageCompression);

  _canSetOutcomeLayout = () => {
    return this._getCurrentQuestionOperations().includes(
      operation.outcomeLayout
    );
  };

  _shouldDisplaySclaerUnit = () =>
    this._getCurrentQuestionOperations().includes(operation.scalerLeftUnit) ||
    this._getCurrentQuestionOperations().includes(operation.scalerRightUnit);

  _shouldDisplayWordLimit = () =>
    this._getCurrentQuestionOperations().includes(operation.maxWordLimit);

  _shouldDisplayDateFormat = () =>
    this._getCurrentQuestionOperations().includes(operation.dateFormat);

  _shouldDisplayCurrencyDropdown = () =>
    this._getCurrentQuestionOperations().includes(operation.dynamicPricing);

  _canSetDynamicPricing = () =>
    this._getCurrentQuestionOperations().includes(operation.currency);

  _canSetMaxOutcome2Display = () => {
    return this._getCurrentQuestionOperations().includes(
      operation.maxOutcome2Display
    );
  };

  renderToggles() {
    const configs =
      this.props.question &&
      this.types[this.props.question.type] &&
      this.types[this.props.question.type].config;
    if (!configs) {
      return <div />;
    } else {
      return configs.map((config, index) => {
        return (
          <ToggleWrapper
            question={this.props.question}
            question_id={this.props.questionIndex}
            update_question_config={this.props.update_question_config}
            ToggleName={config}
          />
        );
      });
    }
  }

  _updateThankyouPage = (thankyouPageAttributes: $Shape<thankyouPageType>) => {
    this.props.update_thankyou_page(
      replace(
        this.props.thankyouPages,
        {
          ...this.props.thankyouPages[this.props.thankyouPageIndex],
          ...thankyouPageAttributes
        },
        this.props.thankyouPageIndex
      )
    );
  };

  _getCurrentThankyouPage = () => {
    return (
      this.props.thankyouPages &&
      this.props.thankyouPages[this.props.thankyouPageIndex]
    );
  };

  _getCurrentShowScore = () => {
    return (
      this.props.thankyouPages &&
      this.props.thankyouPages[this.props.thankyouPageIndex] &&
      this.props.thankyouPages[this.props.thankyouPageIndex].showScore
    );
  };

  _getCurrentSameTabRedirect = () => {
    return !!(
      this.props.thankyouPages &&
      this.props.thankyouPages[this.props.thankyouPageIndex] &&
      this.props.thankyouPages[this.props.thankyouPageIndex].sameTabRedirect
    );
  };

  _handleShowScoreToggleClick = () => {
    this._updateThankyouPage({
      showScore: !this._getCurrentShowScore()
    });
  };

  _hasThankyouPage = () => {
    return (
      this.props.thankyouPages &&
      this.props.thankyouPages[this.props.thankyouPageIndex]
    );
  };

  _initializeScoreToggle = () => {
    //skip if thankyou page is deleted or it has already been initialized
    if (!this._hasThankyouPage() || this._getCurrentShowScore() !== undefined) {
      return;
    }
    //default to show score if user already have score set up
    if (this.props.hasScoreSetting) {
      this._updateThankyouPage({
        showScore: true
      });
    }
  };

  _renderScoreToggle = () => {
    if (this._isEditingThankyouPage() && this.props.hasScoreSetting) {
      this._initializeScoreToggle();
      return (
        <Toggle
          isOn={this._getCurrentShowScore()}
          handleToggleClick={this._handleShowScoreToggleClick}
          toggleName={"Show Score"}
        />
      );
    }
  };

  _handleSameTabRedirectToggleClick = () => {
    this._updateThankyouPage({
      sameTabRedirect: !this._getCurrentSameTabRedirect()
    });
  };

  _renderSameTabRedirectToggle = () => {
    if (this._isEditingThankyouPage()) {
      return (
        <Toggle
          isOn={this._getCurrentSameTabRedirect()}
          handleToggleClick={this._handleSameTabRedirectToggleClick}
          toggleName="Same Tab Redirection"
        />
      );
    }
  };

  _isEditingThankyouPage = () => {
    return this.props.questionIndex === "thankyouPage";
  };

  _isEditingOutcomePage = () => {
    return this.props.questionIndex === QuestionTypeEnum.OutcomePage;
  };

  _handleMediaUploadSucceed = (
    url: string,
    type: ?questionMediaTypeEnumType,
    originalWidth: ?number,
    originalHeight: ?number
  ) => {
    const originalDimension =
      originalWidth && originalHeight
        ? {
            originalWidth: originalWidth,
            originalHeight: originalHeight
          }
        : {};
    if (this._isEditingThankyouPage()) {
      this._updateThankyouPage({
        mediaSpec: {
          src: url,
          type: toNumber(type),
          ...originalDimension
        },
        // To clear the legacy field
        backgroundImage: null
      });
    } else if (this._isEditingOutcomePage()) {
      this.props.update_outcome_page({
        ...this.props.outcomePageConfig,
        media: {
          src: url,
          type: toNumber(type),
          ...originalDimension
        }
      });
    } else {
      this.props.update_question_media(
        {
          type: toNumber(type),
          src: url,
          ...originalDimension
        },
        this.props.questionIndex
      );
    }
  };

  _removeUploadedMedia = () => {
    if (this._isEditingThankyouPage()) {
      this._updateThankyouPage({
        mediaSpec: null,
        backgroundImage: null
      });
    } else if (this._isEditingOutcomePage()) {
      this.props.update_outcome_page({
        ...this.props.outcomePageConfig,
        media: undefined
      });
    } else {
      this.props.update_question_media(null, this.props.questionIndex);
    }
  };

  _renderJumpLogicButton = () => {
    return (
      <UICoreBox justifyContent="between" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            Jump Logic
          </UICoreText>
        </UICoreBox>
        <JumpLogicContainer
          questionID={this.props.question && this.props.question.question_id}
        />
      </UICoreBox>
    );
  };

  _renderSetDynamicPricing = () => {
    return (
      <UICoreBox justifyContent="between" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            Dynamic Pricing
          </UICoreText>
        </UICoreBox>
        <DynamicPricing
          questionID={this.props.question && this.props.question.question_id}
        />
      </UICoreBox>
    );
  };

  _renderThankyouPageDisplayLogic = () => {
    return (
      <UICoreBox justifyContent="between" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            Display Logic
          </UICoreText>
        </UICoreBox>
        <ThankyouPageDisplayLogic
          questionID={this.props.question && this.props.question.question_id}
          jumpLogic={this.props.question && this.props.question.jumpLogic}
        />
      </UICoreBox>
    );
  };

  _hasMedia = () => {
    if (this._isEditingThankyouPage()) {
      return hasMediaSpec(null, this._getCurrentThankyouPage(), null);
    } else if (this._isEditingOutcomePage()) {
      return hasMediaSpec(
        null,
        null,
        this.props.outcomePageConfig && this.props.outcomePageConfig.media
      );
    } else {
      return hasMediaSpec(this.props.question, null, null);
    }
  };

  _renderSetOutcomeMappingButton = () => {
    return (
      <UICoreBox justifyContent="between" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            {"Map to Outcomes"}
          </UICoreText>
        </UICoreBox>
        <OutcomeMapping question={this.props.question} />
      </UICoreBox>
    );
  };

  _renderAddImageButton = () => {
    return (
      <UICoreBox justifyContent="between" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            {"Image & Media"}
          </UICoreText>
        </UICoreBox>
        <UploadMediaButton
          hasMedia={this._hasMedia()}
          key={this.props.questionIndex}
          question={this.props.question}
          questionIndex={this.props.questionIndex}
          onMediaUploadSuccess={this._handleMediaUploadSucceed}
          onRemoveMediaButtonClick={this._removeUploadedMedia}
        />
      </UICoreBox>
    );
  };

  _renderAddScoreButton = () => {
    return (
      <UICoreBox justifyContent="between" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            Score
          </UICoreText>
        </UICoreBox>
        <ScoreButton
          questionID={this.props.question && this.props.question.question_id}
          scoreCalculations={
            this.props.question && this.props.question.scoreCalculations
          }
        />
      </UICoreBox>
    );
  };

  _renderImageCompressionButton = () => {
    return (
      <UICoreBox justifyContent="between" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            Image Compression
          </UICoreText>
        </UICoreBox>
        <ImageCompressionButton
          questionIndex={this.props.questionIndex}
          contents={this._getQuestionContents()}
        />
      </UICoreBox>
    );
  };

  _renderDisplayWordLimit = () => {
    return (
      <UICoreBox justifyContent="between" alignItems="center" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            Word Limit
          </UICoreText>
        </UICoreBox>
        <MaxWordLimit
          questionIndex={this.props.questionIndex}
          contents={this._getQuestionContents()}
        />
      </UICoreBox>
    );
  };

  _renderDisplayDateFormat = () => {
    return (
      <UICoreBox justifyContent="between" alignItems="center" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            Format
          </UICoreText>
        </UICoreBox>
        <DateFormatDropdown
          questionIndex={this.props.questionIndex}
          contents={this._getQuestionContents()}
        />
      </UICoreBox>
    );
  };

  _renderDisplayCurrencyDropdown = () => {
    return (
      <UICoreBox justifyContent="between" alignItems="center" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            Currency
          </UICoreText>
        </UICoreBox>
        <CurrencyDropdown
          questionIndex={this.props.questionIndex}
          contents={this._getQuestionContents()}
        />
      </UICoreBox>
    );
  };

  _renderScalerSteps = () => {
    return (
      <UICoreBox justifyContent="between" alignItems="center" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            Steps
          </UICoreText>
        </UICoreBox>
        <ScalerSteps
          questionIndex={this.props.questionIndex}
          contents={this._getQuestionContents()}
        />
      </UICoreBox>
    );
  };

  _renderLeftScalerUnit = () => {
    return (
      <UICoreBox justifyContent="between" alignItems="center" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            Left Label
          </UICoreText>
        </UICoreBox>
        <ScalerUnit
          position="left"
          questionIndex={this.props.questionIndex}
          contents={this._getQuestionContents()}
        />
      </UICoreBox>
    );
  };

  _renderRightScalerUnit = () => {
    return (
      <UICoreBox justifyContent="between" alignItems="center" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            Right Label
          </UICoreText>
        </UICoreBox>
        <ScalerUnit
          position="right"
          questionIndex={this.props.questionIndex}
          contents={this._getQuestionContents()}
        />
      </UICoreBox>
    );
  };

  _renderSocialShareConfig = () => {
    return (
      <UICoreBox justifyContent="between" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            Social Share
          </UICoreText>
        </UICoreBox>
        <SocialShare />
      </UICoreBox>
    );
  };

  _renderSetMaxOutcome2Display = () => {
    return (
      <UICoreBox alignItems="center" justifyContent="between" direction="row">
        <UICoreBox>
          <UICoreTooltip.WrapAround
            relativeLeft={50}
            size={200}
            text="Maximum number of outcomes to be displayed in the ending page"
          >
            <UICoreText color="black" size="xs">
              Max outcomes
            </UICoreText>
          </UICoreTooltip.WrapAround>
        </UICoreBox>
        <MaxOutcome2DisplayDropdown />
      </UICoreBox>
    );
  };

  _renderSetAvailableSubscriptionPlan = () => {
    return (
      <UICoreBox justifyContent="between" direction="row">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            Available Plans
          </UICoreText>
        </UICoreBox>
        <AvailableSubscriptionPlans
          updateQuestionContent={(content: any) =>
            this.props.updateQuestionContent(
              toNumber(this.props.questionIndex),
              content,
              true // should persist
            )
          }
          questionContent={this._getQuestionContents()}
        />
      </UICoreBox>
    );
  };

  _renderOutcomeSection = (settings: Array<React.Node>) => {
    if (!settings.some(s => s !== false)) {
      return;
    }
    return [
      <UICoreBox>
        <UICoreDivider />
      </UICoreBox>,
      <UICoreBox direction="column">
        <UICoreBox paddingBottom="xm">
          <UICoreText color="black" size="xs" weight="bold">
            Outcome
          </UICoreText>
        </UICoreBox>
        <UICoreBox>{settings}</UICoreBox>
      </UICoreBox>
    ];
  };

  _renderSetOutcomeLayout = () => {
    return (
      <UICoreBox direction="column">
        <UICoreBox>
          <UICoreText color="black" size="xs">
            Layout
          </UICoreText>
        </UICoreBox>
        <OutcomeLayout />
      </UICoreBox>
    );
  };

  render() {
    return (
      <div className="QuestionSettings">
        {/* Button type */}
        {this._canSetOutcomeMapping() && this._renderSetOutcomeMappingButton()}
        {(this._canAddImage() || this._isEditingThankyouPage()) &&
          this._renderAddImageButton()}
        {this._canAddJumpLogic() && this._renderJumpLogicButton()}
        {this._isEditingThankyouPage() &&
          this._renderThankyouPageDisplayLogic()}
        {this._canAddScore() && this._renderAddScoreButton()}
        {this._canImageCompress() && this._renderImageCompressionButton()}
        {this._canSetDynamicPricing() && this._renderSetDynamicPricing()}
        {this._canSetAvailableSubscriptionPlan() &&
          this._renderSetAvailableSubscriptionPlan()}
        {this._isEditingThankyouPage() && this._renderSocialShareConfig()}

        {/* Toggle type */}
        {this.renderToggles()}
        {this._renderScoreToggle()}
        {this._renderSameTabRedirectToggle()}

        {/* Input type */}
        {this._shouldDisplayWordLimit() && this._renderDisplayWordLimit()}
        {this._canAdjustScalerSteps() && this._renderScalerSteps()}
        {this._shouldDisplaySclaerUnit() && this._renderLeftScalerUnit()}
        {this._shouldDisplaySclaerUnit() && this._renderRightScalerUnit()}

        {/* Dropdown type */}
        {this._canSetMaxOutcome2Display() &&
          this._renderSetMaxOutcome2Display()}
        {this._shouldDisplayDateFormat() && this._renderDisplayDateFormat()}
        {this._shouldDisplayCurrencyDropdown() &&
          this._renderDisplayCurrencyDropdown()}

        {/* Custom question specific section */}
        {this._renderOutcomeSection([
          this._canSetOutcomeLayout() && this._renderSetOutcomeLayout()
        ])}
      </div>
    );
  }
}

export default WithFormConfig(QuestionSettings);

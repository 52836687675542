//@flow
import * as React from "react";
import { logReferralEvent } from "../../../../Library/Logger";
import withStyles from "../../../../Helper_HOC/WithStyles";
import {
  type formStyleType,
  type backgroundCssType
} from "../../../../FlowTypes/wispformStyleTypes";
import completePageLogo from "../../../../asset/completePageLogo_4x.png";
import { browserHistory } from "react-router";
import "./style.css";
import "./style.mobile.css";
import {
  UICoreBox,
  UICoreTextEditable,
  UICoreButton,
  UICoreImage,
  UICoreText,
  UICoreFlyOut,
  UICoreInput
} from "../../../../Component/UICore";
import Logo from "../../../../Component/Logo/Logo";
import Desktop from "../../../../Component/Views/Desktop";
import Mobile from "../../../../Component/Views/Mobile";
import { type thankyouPageType } from "../../../WispformTypings";
import { connect } from "react-redux";
import {
  update_thankyou_page,
  update_thankyou_page_just_redux
} from "../../../FormBuilderPage/States/actions";
import { Scrollbars } from "react-custom-scrollbars";
import {
  getTotalAvailableScore,
  getTotalScore,
  isFormFromPaidPlan,
  hasRedirectURL,
  logo as logoFromForm
} from "../../States/Answers/selectors";
import {
  getAllQuestions as getAllQuestionsFromBuilder,
  getThankyouPages as getThankyouPagesFromBuilder,
  getTotalAvailableScore as getTotalAvailableScoreFromBuilder,
  hasRedirectURL as hasRedirectURLFromBuilder,
  logo as logoFromBuilder
} from "../../../FormBuilderPage/States/selectors";
import Question from "../Question";
import {
  replace,
  toArray,
  executeCallbackSafely,
  toUnNullable,
  safeGet,
  isNonEmptyString,
  nonNullOrThrow,
  isNullOrUndefined,
  isLocalStorageAvailable
} from "../../../../Library/Util";
import {
  isMobileScreen,
  isInIframe,
  isIosSDevice
} from "../../../../Library/Window";
import { enableRedirectParentWindowInIframe } from "../../../../Library/GateKeeper/formViewerGateKeeper";
import QuestionMediaRender, {
  hasMediaSpec
} from "../../../../Component/QuestionMediaRender";
import SocialShareIcons from "../../../Share/SocialShareIcons";
import { getShareLink, getShareLinkForForm } from "../../../Share";
import { getSocialShareContent } from "../../../../Library/thankyouPageHelper";
import { isInBuilder } from "../../../../Library/ViewerContext";
import { convertStringRep2TagFilledString } from "../../../../Component/UICore/UICoreTagInput/InputManager";
import {
  getQuestionIdPipedAnswerMap,
  getTagInputOptions
} from "../../../../Library/AnswerPiping";
import { type AnswerType, type questionType } from "../../../QuestionTypes";
import UICoreTagInput from "../../../../Component/UICore/UICoreTagInput";
import EditableButton from "../../../../Component/EditableButton";
import { patchRequest } from "../../../../Library/Request";
import { getFormUid } from "../../../../Library/ViewerContext/formViewerContext";

type Props = {|
  allQuestions?: Array<questionType>,
  isInBuilder?: boolean,
  styles: formStyleType,
  backgroundCss: backgroundCssType,
  answerSecondaryColor: string,
  answerPrimaryColor: string,
  buttonTextColor: string,
  buttonPrimaryColor: string,
  thankyouPageConfig?: thankyouPageType,
  maxScore: number,
  totalScore: number,
  totalScoreFromBuilder: number,
  isFormFromPaidPlan: boolean,
  hasRedirectURL: ?boolean,
  hasRedirectURLFromBuilder: ?boolean,
  isInPreview?: ?boolean,
  logoFromBuilder: ?string,
  logoFromForm: ?string,
  thankyouPagesFromBuilder?: Array<thankyouPageType>,
  lastEditedThankyouPage?: number,
  answers: ?Array<AnswerType>,
  update_thankyou_page: (Array<thankyouPageType>) => void,
  update_thankyou_page_just_redux: (Array<thankyouPageType>) => void
|};

type State = {|
  showLinkEditor: boolean,
  scrollContainer: ?HTMLElement
|};

export const DefaultThankyouNote =
  "Thanks for completing this Wispform! If you enjoyed this online form, you can easily create a similar one for free!";
export const DefaultButtonText = "Create My Wispform";
class CompletePage extends React.Component<Props, State> {
  state = {
    showLinkEditor: false,
    scrollContainer: null
  };

  _buttonNode: ?HTMLElement;

  _handleButtonClick = () => {
    let redirectUrl = this._withHttp(String(this._getButtonLink()));
    patchRequest(`api/v1/forms/${getFormUid()}/form_views/1`, {
      existing_form_view_uuid: isLocalStorageAvailable()
        ? localStorage.getItem("submitted-" + getFormUid())
        : null,
      finalPageButtonClicked: true,
      redirection: redirectUrl
    })
      .then(_ => {})
      .catch(_ => {});
    if (String(this._getButtonLink()) === "https://wispform.com/") {
      logReferralEvent("freeThankYouPageReferalButtonClicked");
    } else {
      logReferralEvent("thankyouPageReferalButtonClicked");
    }
    redirectUrl = convertStringRep2TagFilledString(
      String(redirectUrl),
      getQuestionIdPipedAnswerMap(toArray(this.props.answers))
    );
    if (
      this._isRedirectToWispform() ||
      (this.props.thankyouPageConfig &&
        this.props.thankyouPageConfig.sameTabRedirect)
    ) {
      //Don't open a new tab when restart wispform
      window.location = redirectUrl;
    } else {
      if (isInIframe() && enableRedirectParentWindowInIframe()) {
        executeCallbackSafely(_ => {
          window.top.location.href = redirectUrl;
        });
      } else {
        window.open(redirectUrl);
      }
    }
  };

  _renderThankyouNote = () => {
    if (this.props.thankyouPageConfig) {
      return this.props.thankyouPageConfig.thankyouNote;
    } else {
      return this.props.isFormFromPaidPlan
        ? "Thank you for completing this Wispform!"
        : DefaultThankyouNote;
    }
  };

  _renderButtonText = () => {
    if (this.props.thankyouPageConfig) {
      return this.props.thankyouPageConfig.buttonText;
    } else {
      return this.props.isFormFromPaidPlan ? "Start Over" : DefaultButtonText;
    }
  };

  _getFormURL = () => {
    return window.location && window.location.href;
  };

  _isRedirectToWispform = () => {
    return this._getButtonLink() === this._getFormURL();
  };

  _getButtonLink = () => {
    if (this.props.thankyouPageConfig) {
      return this.props.thankyouPageConfig.redirectURL;
    } else {
      return this.props.isFormFromPaidPlan || this.props.isInPreview
        ? this._getFormURL()
        : "https://wispform.com/";
    }
  };

  _getUpdatedThankyouPagesWithCurrentAttributes = (
    thankyouPageAttributes: $Shape<thankyouPageType>
  ) => {
    if (
      this.props.lastEditedThankyouPage !== undefined &&
      this.props.lastEditedThankyouPage !== null
    ) {
      return replace(
        toArray(this.props.thankyouPagesFromBuilder),
        {
          ...this.props.thankyouPageConfig,
          ...thankyouPageAttributes
        },
        // $FlowFixMe
        this.props.lastEditedThankyouPage
      );
    } else {
      return [];
    }
  };

  _handleThankyouNoteInputBlur = () => {
    this.props.update_thankyou_page(
      this._getUpdatedThankyouPagesWithCurrentAttributes({
        thankyouNote: this._renderThankyouNote()
      })
    );
  };

  _shouldShowScore = () => {
    return (
      this.props.thankyouPageConfig && this.props.thankyouPageConfig.showScore
    );
  };

  _handleThankyouNoteInput = (
    e: SyntheticInputEvent<HTMLInputElement>,
    value: string
  ) => {
    this.props.update_thankyou_page_just_redux(
      this._getUpdatedThankyouPagesWithCurrentAttributes({
        thankyouNote: value
      })
    );
  };

  _updateButtonText = (
    e: SyntheticInputEvent<HTMLInputElement>,
    value: string
  ) => {
    this.props.update_thankyou_page_just_redux(
      this._getUpdatedThankyouPagesWithCurrentAttributes({
        buttonText: value
      })
    );
  };

  _handleButtonInputBlur = () => {
    this.props.update_thankyou_page(
      this._getUpdatedThankyouPagesWithCurrentAttributes({
        buttonText: this._renderButtonText()
      })
    );
  };

  _handleLinkInput = (value: string) => {
    this.props.update_thankyou_page_just_redux(
      this._getUpdatedThankyouPagesWithCurrentAttributes({
        redirectURL: value
      })
    );
  };

  _withHttp = (link: string) => {
    if (link.indexOf("http") >= 0) {
      return link;
    }
    return "http://" + link;
  };

  _handleLinkInputBlur = () => {
    const redirect = this._getButtonLink();
    this.props.update_thankyou_page(
      this._getUpdatedThankyouPagesWithCurrentAttributes({
        redirectURL: redirect
      })
    );
  };

  _renderButton = () => {
    // Only show CTA button and editor when auto redirect url is not set in configuration page
    if (this.props.isInBuilder) {
      return (
        !this.props.hasRedirectURLFromBuilder && (
          <EditableButton
            buttonText={this._renderButtonText()}
            buttonLink={String(this._getButtonLink())}
            onButtonTextBlur={this._handleButtonInputBlur}
            onButtonTextUpdate={this._updateButtonText}
            onLinkUpdate={this._handleLinkInput}
            buttonPrimaryColor={this.props.buttonPrimaryColor}
            buttonTextColor={this.props.buttonTextColor}
            buttonTextFontFamily={
              this.props.styles && this.props.styles.fontFamily
            }
            tagOptionGroups={[
              {
                header: "Answer from Questions: ",
                tags: getTagInputOptions(
                  toArray(this.props.allQuestions),
                  Infinity
                )
              }
            ]}
          />
        )
      );
    } else {
      return (
        !this.props.hasRedirectURL && (
          <UICoreButton
            onClick={this._handleButtonClick}
            hasShadow={false}
            size="lg"
            hexColor={this.props.buttonPrimaryColor}
            textHexColor={this.props.buttonTextColor}
          >
            {this._renderButtonText()}
          </UICoreButton>
        )
      );
    }
  };

  _renderLogo = () => {
    if (this.props.isInBuilder && this.props.logoFromBuilder) {
      return (
        <UICoreImage src={this.props.logoFromBuilder} width={75} height={75} />
      );
    } else if (this.props.logoFromForm) {
      return (
        <UICoreImage src={this.props.logoFromForm} width={75} height={75} />
      );
    } else {
      return <Logo width={45} height={45} />;
    }
  };

  _renderThumbUp = () => {
    if (hasMediaSpec(null, this.props.thankyouPageConfig, null)) {
      return null;
    } else {
      return (
        <UICoreBox
          width="100px"
          height="100px"
          name="thumb up"
          shape="rounded"
          className="CompletePage-Thumb"
          hexColor={this.props.answerSecondaryColor}
          alignItems="center"
          justifyContent="center"
        >
          <i
            style={{ color: this.props.styles.answer }}
            className="ion-thumbsup CompletePage-Thumb-Icon"
          />
        </UICoreBox>
      );
    }
  };

  _getAlignment = (): "left" | "center" => {
    if (this._renderThankyouNote().length > 38 && isMobileScreen()) {
      return "left";
    } else {
      return "center";
    }
  };

  _extraMobileSubmitButtonBottomPadding = () => {
    return isInIframe() && isIosSDevice() ? 120 : 0;
  };

  _getSocialShareContent = () => {
    const savedContent = safeGet(
      _ =>
        toUnNullable(this.props.thankyouPageConfig).socialShareConfig.shareText
    );
    if (isNonEmptyString(savedContent)) {
      return getSocialShareContent(
        convertStringRep2TagFilledString(nonNullOrThrow(savedContent), {
          ...getQuestionIdPipedAnswerMap(toArray(this.props.answers)),
          formScore: this.props.totalScore
        })
      );
    } else {
      return getSocialShareContent(savedContent);
    }
  };

  _renderSocialShareSection = () => {
    if (
      this.props.thankyouPageConfig &&
      toUnNullable(this.props.thankyouPageConfig).showSocialShare
    ) {
      return (
        <UICoreBox disabled={this.props.isInBuilder} padding="sm">
          <SocialShareIcons
            link={getShareLinkForForm()}
            title={this._getSocialShareContent()}
          />
        </UICoreBox>
      );
    }
  };

  render() {
    return (
      <UICoreBox
        width="100%"
        height="100%"
        dangerous_style={{
          ...this.props.backgroundCss
        }}
      >
        <UICoreBox
          name="wrapper"
          position="relative"
          width="100%"
          height="100%"
        >
          <UICoreBox
            name="branding"
            alignItems="end"
            justifyContent="start"
            direction="row"
            width="100%"
            position="absolute"
            top="16px"
            left="16px"
          >
            {this._renderLogo()}
          </UICoreBox>
          <UICoreBox
            innerRef={ref => {
              if (!this.state.scrollContainer) {
                this.setState({ scrollContainer: ref });
              }
            }}
            justifyContent="center"
            height="100%"
            dangerous_style={{
              paddingBottom: isMobileScreen()
                ? `${68 + this._extraMobileSubmitButtonBottomPadding()}px`
                : "0px"
            }}
          >
            <Scrollbars
              autoHeight
              autoHeightMax={
                this.state.scrollContainer &&
                this.state.scrollContainer.offsetHeight
              }
            >
              <UICoreBox padding="md" name="main content" alignItems="center">
                {hasMediaSpec(null, this.props.thankyouPageConfig, null) && [
                  <Desktop>
                    <UICoreBox
                      maxWidth="450px"
                      width="100%"
                      marginBottom="xm"
                      alignItems="center"
                    >
                      <QuestionMediaRender
                        maxHeight="300px"
                        thankyouPage={this.props.thankyouPageConfig}
                      />
                    </UICoreBox>
                  </Desktop>,
                  <Mobile>
                    <UICoreBox
                      maxWidth="450px"
                      width="100%"
                      marginBottom="xm"
                      alignItems="center"
                    >
                      <QuestionMediaRender
                        maxHeight="250px"
                        thankyouPage={this.props.thankyouPageConfig}
                      />
                    </UICoreBox>
                  </Mobile>
                ]}

                {this._shouldShowScore() ? (
                  <UICoreBox
                    width="100px"
                    height="100px"
                    name="thumb up"
                    shape="rounded"
                    className="CompletePage-Thumb"
                    hexColor={this.props.answerSecondaryColor}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <UICoreText
                      fontFamily={
                        this.props.styles && this.props.styles.fontFamily
                      }
                      weight="bold"
                      dangerous_style={{ fontSize: "28px" }}
                      hexColor={this.props.styles.answer}
                    >
                      {this.props.totalScore}
                    </UICoreText>
                  </UICoreBox>
                ) : (
                  this._renderThumbUp()
                )}

                <Desktop>
                  <UICoreBox
                    alignItems="center"
                    width="100%"
                    name="text"
                    marginTop="md"
                  >
                    {this.props.isInBuilder ? (
                      <UICoreTextEditable
                        fontFamily={
                          this.props.styles && this.props.styles.fontFamily
                        }
                        placeholderColor={this.props.answerSecondaryColor}
                        inputBoxColor={this.props.answerSecondaryColor}
                        allowMultipleLines={true}
                        alignment={this._getAlignment()}
                        overflow="wrap"
                        size="lg"
                        weight="bold"
                        width="50%"
                        autoSelect={true}
                        hexColor={this.props.styles.answer}
                        placeholder="Type your thankyou note here..."
                        onInput={this._handleThankyouNoteInput}
                        onBlur={this._handleThankyouNoteInputBlur}
                      >
                        {this._renderThankyouNote()}
                      </UICoreTextEditable>
                    ) : (
                      <UICoreBox width="50%">
                        <UICoreText
                          parseLink={true}
                          alignment={this._getAlignment()}
                          overflow="wrap"
                          size="lg"
                          weight="bold"
                          hexColor={this.props.styles.answer}
                          fontFamily={
                            this.props.styles && this.props.styles.fontFamily
                          }
                        >
                          {this._renderThankyouNote()}
                        </UICoreText>
                      </UICoreBox>
                    )}
                  </UICoreBox>
                </Desktop>

                <Mobile>
                  <UICoreBox
                    alignItems="center"
                    width="100%"
                    name="text"
                    marginTop="md"
                    marginBottom="lg"
                    dangerous_style={
                      this._extraMobileSubmitButtonBottomPadding()
                        ? {
                            paddingBottom: `${this._extraMobileSubmitButtonBottomPadding()}px`
                          }
                        : {}
                    }
                  >
                    <UICoreText
                      parseLink={true}
                      alignment={this._getAlignment()}
                      overflow="wrap"
                      size="lg"
                      weight="bold"
                      hexColor={this.props.styles.answer}
                    >
                      {this._renderThankyouNote()}
                    </UICoreText>
                  </UICoreBox>
                </Mobile>

                {this._renderSocialShareSection()}

                <Desktop>
                  <UICoreBox maxWidth="400px" marginTop="xl">
                    {this._renderButton()}
                  </UICoreBox>
                </Desktop>
              </UICoreBox>
            </Scrollbars>
          </UICoreBox>
          <Mobile>
            <UICoreBox
              width="100%"
              position="fixed"
              padding="sm"
              bottom={`${this._extraMobileSubmitButtonBottomPadding()}px`} //Safari bug
            >
              {!this.props.hasRedirectURL && (
                <UICoreButton
                  onClick={this._handleButtonClick}
                  hasShadow={false}
                  size="lg"
                  hexColor={this.props.buttonPrimaryColor}
                  textHexColor={this.props.buttonTextColor}
                >
                  {this._renderButtonText()}
                </UICoreButton>
              )}
            </UICoreBox>
          </Mobile>
        </UICoreBox>
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    allQuestions: getAllQuestionsFromBuilder(state.Question),
    hasRedirectURLFromBuilder: hasRedirectURLFromBuilder(state.Question),
    hasRedirectURL: hasRedirectURL(state.Answers),
    logoFromForm: logoFromForm(state.Answers),
    logoFromBuilder: logoFromBuilder(state.Question),
    maxScore: getTotalAvailableScore(state.Answers),
    totalScore: getTotalScore(state.Answers),
    totalScoreFromBuilder: getTotalAvailableScoreFromBuilder(state.Question),
    isFormFromPaidPlan: isFormFromPaidPlan(state.Answers),
    lastEditedThankyouPage: state.Question.last_edited_thankyou_page,
    thankyouPagesFromBuilder: getThankyouPagesFromBuilder(state.Question),
    answers: state.Answers.answers
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    update_thankyou_page: (pages: Array<thankyouPageType>) => {
      dispatch(update_thankyou_page(pages));
    },
    update_thankyou_page_just_redux: (pages: Array<thankyouPageType>) => {
      dispatch(update_thankyou_page_just_redux(pages));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(CompletePage));

// @flow
import { SizeTypeEnum } from "../FlowTypes/sizeTypes";
import { isSmallMobileScreen } from "./Window";

export function getFontSize(size: ?$Values<typeof SizeTypeEnum>) {
  switch (size) {
    case SizeTypeEnum.xxm:
      return "12px";
    case SizeTypeEnum.xm:
      return "14px";
    case SizeTypeEnum.sm:
      return "16px";
    case SizeTypeEnum.md:
      return "18px";
    case SizeTypeEnum.lg:
      return isSmallMobileScreen() ? "20px" : "22px";
    case SizeTypeEnum.xl:
      return isSmallMobileScreen() ? "22px" : "24px";
    default:
      return "16px";
  }
}

//@flow
//withNotifications HOC provides a function to let you display notifications on user's screen

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { showNotification } from "../Pages/WispTable/State/NotificationState/action";
import { type notificationType } from "../Component/Notification";

type State = {||};
export type InjectedProps = {|
  showNotification: (
    notificationType,
    string,
    string,
    ?string,
    ?() => void
  ) => void
|};

function withNotifications<PassedProps: Object>(
  WrappedComponent: React.ComponentType<PassedProps>
): React.ComponentType<$Diff<PassedProps, InjectedProps>> {
  return class Wrapper extends React.Component<PassedProps, State> {
    render() {
      return (
        <WrappedComponent
          {...this.props}
          showNotification={this.props.showNotification}
        />
      );
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showNotification: (
      notificationType: notificationType,
      title: string,
      description: string,
      actionName?: string,
      onActionClick?: () => void
    ) =>
      dispatch(
        showNotification(
          notificationType,
          title,
          description,
          actionName,
          onActionClick
        )
      )
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withNotifications
);

export type notificationTypes = notificationType;

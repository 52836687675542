// @flow

import formConfiguration, {
  type questionType,
  type AnswerType
} from "../Pages/QuestionTypes";
import { type tagInputTagMapType } from "../FlowTypes/UICoreTagInputTypes";
import { toArray, isNullOrUndefined } from "./Util";

/**
 * Construct the tag id,content map from the visible questions
 */
export function getTagInputOptions(
  visibleQuestions: Array<questionType>,
  currentEditingQuestionIndex: number
): tagInputTagMapType {
  return toArray(visibleQuestions).reduce((prev, question, index) => {
    if (index >= currentEditingQuestionIndex) {
      if (question.type == "HiddenField") {
        return {
          ...prev,
          [question.question_id]: {
            id: question.question_id,
            content: `Hidden Field: ${question.title}`
          }
        };
      } else {
        return prev;
      }
    } else {
      return {
        ...prev,
        [question.question_id]: {
          id: question.question_id,
          content: `Q${index + 1}: ${question.title}`
        }
      };
    }
  }, {});
}

export function getQuestionIdPipedAnswerMap(
  answers: Array<AnswerType>
): { [string]: string } {
  //$FlowFixMe
  const questionsConfig = new formConfiguration().types;
  return toArray(answers).reduce((prev, answer) => {
    const questionConfig = questionsConfig[answer.type];
    if (
      isNullOrUndefined(questionConfig) ||
      isNullOrUndefined(questionConfig.getPipedAnswerString)
    ) {
      return prev;
    }
    return {
      ...prev,
      [answer.question_id]: questionConfig.getPipedAnswerString(answer.answer)
    };
  }, {});
}

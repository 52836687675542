//@flow
import {
  toArray,
  toUnNullable,
  isEmptyArray,
  toNumber,
  getMaxFromNumberArray,
  isNonEmptyArray,
  isNonEmptyString,
  safeGet,
  nonNullOrThrow,
  isNonNull,
  isNullOrUndefined
} from "../../../../Library/Util";
import { createSelector } from "reselect";
import {
  PAYMENT,
  type questionScoreCalculationsType,
  type questionScoreCalculationType
} from "../../../../FlowTypes/scoreCalculationTypes";
import { calculatePrice } from "../../../../Library/DynamicPrice";
import {
  type background,
  backgroundType
} from "../../../../FlowTypes/wispformStyleTypes";
import { getBackgroundImageWithAutoPlatformDetection } from "../../../../Library/FormStyleDataStructureConversion";
import { isWhitelistedBackgroundImage } from "../../../../Helper_HOC/WithFeatureCheck/whitelistedImages";
import { getFormRedirectLinkWithAnswerParam } from "../../../../Library/FormRedirect";
import { QuestionTypeEnum } from "../../../QuestionTypes";
import { getEligibleOutcomeIDs } from "../../../../Library/OutcomeDisplay";
import { type TOutcomeData } from "../../../../Component/GenericFormContent/type";

export function isFormFromPaidPlan(state: any) {
  // Similar to isPro isBusiness isPaid but for forms
  return (
    state.form_plan === "Premium" ||
    state.form_plan === "Pro" ||
    state.form_plan === "Business"
  );
}

export function isFormFromDelinquentAccount(state: any) {
  return state.form_plan === "Delinquent";
}

export function getVisibleQuestions(state: any) {
  return (
    state.questions && _filterHiddenQuestions(state.questions.visibleQuestions)
  );
}

function _filterHiddenQuestions(questions?: Array<any>): Array<any> {
  if (!questions) {
    return [];
  }
  return toArray(questions).filter(question =>
    safeGet(_ => question.type !== "HiddenField")
  );
}

export const getAllAnswersInMap = createSelector(
  [getAllAnswers],
  allAnswers => {
    return toArray(allAnswers).reduce((answersMap, currentAnswer) => {
      return {
        ...answersMap,
        [currentAnswer.question_id]: currentAnswer
      };
    }, {});
  }
);

export const needToGetStripeToken = createSelector(
  [getVisibleQuestions],
  visibleQuestions => {
    return toArray(visibleQuestions).some(
      question =>
        question.type === PAYMENT ||
        question.type === QuestionTypeEnum.Subscription
    );
  }
);

export const hasPaymentQuestion = createSelector(
  [getVisibleQuestions],
  visibleQuestions => {
    return toArray(visibleQuestions).some(
      question => question.type === PAYMENT
    );
  }
);

export const hasAnyJumpLogic = createSelector(
  [getAllQuestions],
  allQuestions => {
    return toArray(allQuestions).some(
      question =>
        question.jumpLogic != null &&
        isNonEmptyArray(question.jumpLogic.branches)
    );
  }
);

export const hasAnswerPipe = createSelector([getAllQuestions], allQuestions => {
  return toArray(allQuestions).some(question =>
    String(question.title).includes("@{")
  );
});

export const paymentQuestionID = createSelector(
  [getVisibleQuestions],
  visibleQuestions => {
    return toUnNullable(
      toArray(visibleQuestions).find(question => question.type === PAYMENT)
    ).question_id;
  }
);

export const paymentQuestionCurrency = createSelector(
  [getVisibleQuestions],
  visibleQuestions => {
    return (
      safeGet(
        _ =>
          nonNullOrThrow(
            toArray(visibleQuestions).find(
              question => question.type === PAYMENT
            )
          ).contents.currency
      ) || "usd"
    );
  }
);

export const subscriptionQuestionID = createSelector(
  [getVisibleQuestions],
  visibleQuestions => {
    return toUnNullable(
      toArray(visibleQuestions).find(
        question => question.type === QuestionTypeEnum.Subscription
      )
    ).question_id;
  }
);

export const paymentQuestionLogic = createSelector(
  [getVisibleQuestions],
  visibleQuestions => {
    const paymentQuestion = toArray(visibleQuestions).find(
      question => question.type === PAYMENT
    );
    return (
      paymentQuestion &&
      paymentQuestion.config &&
      paymentQuestion.config.dynamicPricing
    );
  }
);

export const paymentQuestionDefaultAmount = createSelector(
  [getVisibleQuestions],
  visibleQuestions => {
    const paymentQuestion = toArray(visibleQuestions).find(
      question => question.type === PAYMENT
    );
    return (
      paymentQuestion &&
      paymentQuestion.config &&
      paymentQuestion.config.paymentAmount
    );
  }
);

export const getDynamicPriceAmount = createSelector(
  [paymentQuestionLogic, paymentQuestionDefaultAmount, getAllAnswers],
  (logic, defaultAmount, answers) => {
    return calculatePrice(logic, defaultAmount, answers);
  }
);

export const getAllQuestionsIdIndexMap = createSelector(
  [getAllQuestions],
  allQuestions => {
    return toArray(allQuestions).reduce(
      (questionIdIndexMap, currentQuestion, currentQuestionIndex) => {
        return {
          ...questionIdIndexMap,
          [currentQuestion.question_id]: currentQuestionIndex
        };
      },
      {}
    );
  }
);

export const paymentQuestionIndexInAllQuestions = createSelector(
  [getAllQuestionsIdIndexMap, paymentQuestionID],
  (allQuestionsIdIndexMap, paymentQuestionID) => {
    return allQuestionsIdIndexMap[paymentQuestionID];
  }
);

export const subscriptionQuestionIndexInAllQuestions = createSelector(
  [getAllQuestionsIdIndexMap, subscriptionQuestionID],
  (allQuestionsIdIndexMap, subscriptionQuestionID) => {
    return allQuestionsIdIndexMap[subscriptionQuestionID];
  }
);

export const hasSubscriptionQuestion = createSelector(
  [getAllQuestions],
  allQuestions => {
    return toArray(allQuestions).some(
      question => question.type === QuestionTypeEnum.Subscription
    );
  }
);

export const firstMonetaryQuestionType = createSelector(
  [getAllQuestions],
  allQuestions => {
    const subscriptionQuestionIndex = toArray(allQuestions).findIndex(
      question => question.type === QuestionTypeEnum.Subscription
    );
    const paymentQuestionIndex = toArray(allQuestions).findIndex(
      question => question.type === QuestionTypeEnum.Payment
    );
    // No monetary question exist
    if (subscriptionQuestionIndex === -1 && paymentQuestionIndex === -1) {
      return null;
    } else if (
      // only one monetary question exist
      subscriptionQuestionIndex === -1 ||
      paymentQuestionIndex === -1
    ) {
      if (subscriptionQuestionIndex > -1) {
        return QuestionTypeEnum.Subscription;
      } else {
        return QuestionTypeEnum.Payment;
      }
    } else {
      // both monetary question exist
      if (subscriptionQuestionIndex < paymentQuestionIndex) {
        return QuestionTypeEnum.Subscription;
      } else {
        return QuestionTypeEnum.Payment;
      }
    }
  }
);

export const getAvailableSubscriptionPlans = createSelector(
  [getAllQuestions],
  allQuestions => {
    const subscriptionQuestion = toArray(allQuestions).find(
      question => question.type === QuestionTypeEnum.Subscription
    );
    if (isNullOrUndefined(subscriptionQuestion)) {
      return [];
    } else {
      return (
        safeGet(
          _ => nonNullOrThrow(subscriptionQuestion).contents.availablePlans
        ) || []
      );
    }
  }
);

export const getSubscriptionPlanIfAny = createSelector(
  [getAllAnswers],
  answers => {
    const subscriptionAnswer = toArray(answers).find(
      answer => answer.type === QuestionTypeEnum.Subscription
    );
    if (subscriptionAnswer) {
      return subscriptionAnswer.answer.planID;
    } else {
      return null;
    }
  }
);

export const getFirstEmailAnswer = createSelector([getAllAnswers], answers => {
  const emailAnswer = toArray(answers).find(
    answer => answer.type === QuestionTypeEnum.Email
  );
  if (emailAnswer) {
    return emailAnswer.answer;
  } else {
    return null;
  }
});

export const getStyleBackground = (state: any): background => {
  return state && state.styles && state.styles.background;
};

export const hasBackgroundImage = createSelector(
  [getStyleBackground],
  (background: background) => {
    return (
      background.type === backgroundType.image &&
      isNonEmptyString(getBackgroundImageWithAutoPlatformDetection(background))
    );
  }
);

export const hasUnwhitelistedBackgroundImage = createSelector(
  [getStyleBackground],
  (background: background) => {
    return (
      background.type === backgroundType.image &&
      ((isNonEmptyString(background.imageMobile) &&
        !isWhitelistedBackgroundImage(String(background.imageMobile))) ||
        (isNonEmptyString(background.imageDesktop) &&
          !isWhitelistedBackgroundImage(String(background.imageDesktop))))
    );
  }
);

function isQuestionAnswered(answer) {
  return (
    answer &&
    answer.answer &&
    ((Array.isArray(answer.answer) && answer.answer.length > 0) ||
      (!Array.isArray(answer.answer) && answer.answer != ""))
  );
}

export const getVisibleQuestionsCount = createSelector(
  [getVisibleQuestions],
  visibleQuestions => {
    return toArray(visibleQuestions).length;
  }
);

export const getNumOfVisibleQuestionsAnswered = createSelector(
  [getVisibleQuestions, getAllAnswersInMap],
  (visibleQuestions, answersMap) => {
    return toArray(visibleQuestions).filter(question => {
      const answer = answersMap[question.question_id];
      return isQuestionAnswered(answer);
    }).length;
  }
);

export function getAllQuestions(state: any) {
  return toArray(state.questions && state.questions.questions);
}

export function getAllAnswers(state: any) {
  return state.answers;
}

export function hasCustomizedThankyouPage(state: any) {
  return (
    state.questions &&
    state.questions.pages &&
    state.questions.pages.thankyouPages &&
    isNonEmptyArray(state.questions.pages.thankyouPages.pages)
  );
}

export function getThankyouPageDisplayLogic(state: any) {
  return (
    state.questions &&
    state.questions.pages &&
    state.questions.pages.thankyouPages &&
    state.questions.pages.thankyouPages.displayLogic
  );
}

function getMaxScore(scoreCalculations: questionScoreCalculationsType) {
  const getScore = scoreCalculation => toNumber(scoreCalculation.score);
  return getMaxFromNumberArray(toArray(scoreCalculations).map(getScore));
}

export const getTotalAvailableScore = createSelector(
  [getVisibleQuestions],
  allVisibleQuestions => {
    return toArray(allVisibleQuestions).reduce((totalScore, question) => {
      if (question && isNonEmptyArray(question.scoreCalculations)) {
        return totalScore + getMaxScore(question.scoreCalculations);
      } else {
        return totalScore;
      }
    }, 0);
  }
);

function getScore(
  scoreCalculations: questionScoreCalculationsType,
  answer,
  questionType: string
): number {
  const matchedCalculations = toArray(scoreCalculations).filter(
    (scoreCalculation: questionScoreCalculationType) => {
      let rawAnswer = answer && answer.answer;
      //TODO: move to questionTypeConfig
      if (
        questionType === "MultiChoices" ||
        questionType === "DropDown" ||
        questionType === "PictureChoice"
      ) {
        const choiceContents = toArray(rawAnswer).map(
          answer => answer.choiceContent
        );
        return choiceContents.includes(scoreCalculation.targetAnswer);
      }
      return scoreCalculation.targetAnswer == rawAnswer;
    }
  );
  if (
    isEmptyArray(matchedCalculations) ||
    isNullOrUndefined(matchedCalculations)
  )
    return 0;
  return matchedCalculations.reduce((prev, curr) => {
    if (curr.operation === "add") {
      return prev + curr.score;
    } else {
      return prev - curr.score;
    }
  }, 0);
}

export const getTotalScore = createSelector(
  [getAllAnswersInMap, getVisibleQuestions],
  (idAnswerMap, visibleQuestions) => {
    return toArray(visibleQuestions).reduce((totalScore, question) => {
      if (question && isNonEmptyArray(question.scoreCalculations)) {
        return (
          totalScore +
          getScore(
            question.scoreCalculations,
            idAnswerMap[question.question_id],
            question.type
          )
        );
      } else {
        return totalScore;
      }
    }, 0);
  }
);

export const getScorePercentage = createSelector(
  [getTotalScore, getTotalAvailableScore],
  (totalScore, totalAvailableScore) => {
    return `${totalScore}/${totalAvailableScore}`;
  }
);

export const hasScoreCalculation = createSelector(
  [getAllQuestions],
  questions => {
    return toArray(questions).some(question =>
      isNonEmptyArray(question.scoreCalculations)
    );
  }
);

export function getFormConfiguration(state: any) {
  return state.configurations || {};
}

export const getRedirectURL = createSelector(
  [getFormConfiguration, getAllAnswers],
  (configuration, asnwers) => {
    return getFormRedirectLinkWithAnswerParam(
      configuration.redirect_url,
      configuration.redirectParam,
      asnwers
    );
  }
);

export const hasRedirectURL = createSelector([getRedirectURL], redirecrtURL => {
  return isNonEmptyString(redirecrtURL);
});

export const logo = createSelector([getFormConfiguration], configuration => {
  return configuration.logo;
});

export const hasLogo = createSelector([logo], logo => {
  return logo != null;
});

export const closeFormSetting = createSelector(
  [getFormConfiguration],
  formConfiguration => {
    return formConfiguration.close_form_setting;
  }
);

export const customCloseFormText = createSelector(
  [getFormConfiguration],
  formConfiguration => {
    return formConfiguration.custom_close_form_text;
  }
);

export const buttonTextConfig = createSelector(
  [getFormConfiguration],
  configuration => {
    return configuration.buttonText || {};
  }
);

export const hasWelcomePage = createSelector(
  [getVisibleQuestions],
  visibleQuestions => {
    const firstQuestion = toArray(visibleQuestions)[0];
    if (firstQuestion && firstQuestion.type === "WelcomePage") {
      return true;
    } else {
      return false;
    }
  }
);

export const getHiddenFieldVariables = createSelector(
  [getFormConfiguration],
  configuration => configuration.hiddenFieldVariables
);

export const hasHiddenFieldVariables = createSelector(
  [getHiddenFieldVariables],
  hiddenFields => isNonEmptyArray(hiddenFields)
);

export const getOutcomePageContentConfig = (state: any) => {
  return (
    state.questions &&
    state.questions.pages &&
    state.questions.pages.outcomePage &&
    state.questions.pages.outcomePage.pageContentConfig
  );
};

export const getMaxOutcomes = (state: any) => {
  return (
    state.questions &&
    state.questions.pages &&
    state.questions.pages.outcomePage &&
    state.questions.pages.outcomePage.maxOutcomes
  );
};

function _getOutcomeIdMap(outcomes: Array<TOutcomeData>): {
  [string]: TOutcomeData
} {
  return outcomes.reduce((prev, outcome) => {
    prev[outcome.id] = outcome;
    return prev;
  }, {});
}

export const getEligibleOutcomes = createSelector(
  [
    getAllAnswersInMap,
    getOutcomePageContentConfig,
    getAllQuestions,
    getMaxOutcomes
  ],
  (answersMap, outcomePageContentConfig, questions, maxOutcomes) => {
    // null check given this can be called from builder path as well
    if (outcomePageContentConfig == null) {
      return null;
    }
    const eligibleOutcomeIDs = getEligibleOutcomeIDs(
      questions,
      answersMap,
      toArray(safeGet(_ => outcomePageContentConfig.outcomeConfig.outcomes)),
      maxOutcomes || 1 // max allowed outcomes
    );
    const outcomeIdMap = _getOutcomeIdMap(
      toArray(safeGet(_ => outcomePageContentConfig.outcomeConfig.outcomes))
    );
    return {
      ...outcomePageContentConfig,
      outcomeConfig: {
        ...safeGet(_ => outcomePageContentConfig.outcomeConfig),
        outcomes: eligibleOutcomeIDs.map(id => outcomeIdMap[id])
      }
    };
  }
);

//@flow
import UI_Statement from "../Component/QuestionComponents/UI_Statement";
import UI_MultiChoices, {
  type multichoiceAnswerType
} from "../Component/QuestionComponents/UI_MultiChoices";
import UI_Rating from "../Component/QuestionComponents/UI_Rating";
import UI_DropDown from "../Component/QuestionComponents/UI_DropDown";
import BuilderDropdown from "../Component/QuestionComponents/UI_DropDown/builderDropdown";
import BuilderMultichoices from "../Component/FormBuilderQuestionComponents/MultichoiceBuilderComponent";
import UI_ShortText from "../Component/QuestionComponents/UI_ShortText";
import UI_LongText from "../Component/QuestionComponents/UI_LongText";
import UI_WelcomePage from "../Component/QuestionComponents/UI_WelcomePage";
import UI_FileUpload, {
  type submitContentType
} from "../Component/QuestionComponents/UI_FileUpload";
import UI_YesNo from "../Component/QuestionComponents/UI_YesNo";
import MultichoiceChart from "../Component/Charts/MultichoiceChart";
import UI_CodeBlock from "../Component/QuestionComponents/UI_CodeBlock";
import UI_Payment from "../Component/QuestionComponents/UI_Payment";
import UI_PictureChoice, {
  type choiceAnswerType as pictureChoiceAnswerType
} from "../Component/QuestionComponents/UI_PictureChoice";
import UI_OpinionScale from "../Component/QuestionComponents/UI_OpinionScale";
import UI_Number from "../Component/QuestionComponents/UI_Number";
import UI_Date from "../Component/QuestionComponents/UI_Date";
import UI_Email from "../Component/QuestionComponents/UI_Email";
import UI_Subscription from "../Component/QuestionComponents/UI_Subscription";
import RatingChart from "../Component/Charts/RatingChart";
import Comperators from "../Middlewares/LogicJumpMiddleware/comperators";
import { type formPlan, operation } from "./WispformTypings";
import { type tableSchemaColumnType } from "../Pages/WispTable/Configuration";
import { type logicJumpType } from "../FlowTypes/jumpLogicTypes";
import {
  toArray,
  explode,
  isNonEmptyString,
  isStringNumericOrNull,
  isNumber,
  isNullOrUndefined,
  nonNullOrThrow
} from "../Library/Util";
import {
  type formStyleType,
  backgroundType
} from "../FlowTypes/wispformStyleTypes";
import { type hiddenFieldVariablesType } from "../FlowTypes/hiddenFieldTypes";
import { type questionMediaType } from "../FlowTypes/questionTypes";
import { isFormFromPaidPlan } from "../Library/ViewerContext/formViewerContext";
import { logDebugEvent, DebugEvents } from "../Library/Logger/debugLogger";
import { logErrorInfo } from "../Helper_HOC/WithErrorBoundary";
import {
  hasValidStripeToken,
  getStripeValidationError
} from "../Component/QuestionComponents/Shared/PaymentCard/stripeToken";
import { type TFormType } from "../FlowTypes/forms";
import { type ToutcomeDisplayLogic } from "../FlowTypes/outcomeDisplayLogicTypes";

export default function formConfiguration() {
  this.types = {
    ShortText: {
      typeName: SHORTTEXT,
      iconClassName: "fa-pencil",
      formBuilderUIComponent: UI_ShortText,
      formUIComponent: UI_ShortText,
      defaultResponse: "",
      delaySubmit: false,
      displayName: "Short Answer",
      config: ["Required"],
      operations: [operation.image, operation.jumpLogic],
      validators: [],
      chart: null,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (formData: string) => formData,
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "ShortText",
          config: {}
        };
      },
      getAnswerChoices: null,
      comperators: [
        Comperators.contain,
        Comperators.notContain,
        Comperators.textStartWith,
        Comperators.textEndWith
      ],
      getPipedAnswerString: (answer: any): string => {
        return String(answer);
      }
    },
    Number: {
      typeName: NUMBER,
      iconClassName: "fa-hashtag",
      formBuilderUIComponent: UI_Number,
      formUIComponent: UI_Number,
      defaultResponse: "",
      delaySubmit: false,
      displayName: "Number",
      config: ["Required"],
      operations: [operation.image, operation.jumpLogic],
      validators: [],
      chart: null,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (formData: string) => formData,
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "ShortText",
          config: {}
        };
      },
      getAnswerChoices: null,
      comperators: [
        Comperators.numericIs,
        Comperators.numericIsNot,
        Comperators.numericLessThan,
        Comperators.numericMoreThan
      ],
      getPipedAnswerString: (answer: any): string => {
        return String(answer);
      }
    },
    LongText: {
      typeName: LONGTEXT,
      iconClassName: "fa-pencil",
      formBuilderUIComponent: UI_LongText,
      formUIComponent: UI_LongText,
      defaultResponse: "",
      delaySubmit: false,
      displayName: "Long Answer",
      config: ["Required"],
      operations: [
        operation.image,
        operation.jumpLogic,
        operation.maxWordLimit
      ],
      validators: ["maxWordCount"],
      chart: null,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (formData: string) => formData,
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "LongText",
          config: {}
        };
      },
      getAnswerChoices: null,
      comperators: [
        Comperators.contain,
        Comperators.notContain,
        Comperators.textStartWith,
        Comperators.textEndWith
      ],
      getPipedAnswerString: (answer: any): string => {
        return String(answer);
      }
    },

    MultiChoices: {
      typeName: MULTICHOICES,
      iconClassName: "fa-bars",
      formBuilderUIComponent: BuilderMultichoices,
      formUIComponent: UI_MultiChoices,
      defaultResponse: [],
      defaultContent: [""],
      delaySubmit: false,
      displayName: "Multiple Choice",
      config: ["Add other", "Multiple Selection", "Required"],
      operations: [
        operation.image,
        operation.jumpLogic,
        operation.score,
        operation.outcomeMapping
      ],
      validators: [],
      chart: MultichoiceChart,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (
        formData: Array<{| index: number, choiceContent: string |}>
      ) => formData.map(choiceInfo => choiceInfo.choiceContent),
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "MultiSelect",
          config: { choices: question.contents }
        };
      },
      getAnswerChoices: (question: questionType) => {
        return question.contents.map(choice => {
          return { display: choice, value: choice };
        });
      },
      comperators: [
        Comperators.multiSelectContain,
        Comperators.multiSelectNotContain,
        Comperators.multiSelectIs
      ],
      getPipedAnswerString: (answer: Array<multichoiceAnswerType>): string => {
        return toArray(answer)
          .map(choice => choice.choiceContent)
          .join(" and ");
      }
    },

    PictureChoice: {
      typeName: PICTURE_CHOICE,
      iconClassName: "fa-image",
      formBuilderUIComponent: UI_PictureChoice,
      formUIComponent: UI_PictureChoice,
      defaultResponse: [],
      defaultContent: [""],
      delaySubmit: false,
      displayName: "Picture Choice",
      config: ["Multiple Selection", "Required"],
      operations: [
        operation.image,
        operation.jumpLogic,
        operation.score,
        operation.outcomeMapping
      ],
      validators: [],
      chart: MultichoiceChart,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (
        formData: Array<{| index: number, choiceContent: string |}>
      ) => formData.map(choiceInfo => choiceInfo.choiceContent),
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "MultiSelect",
          config: {
            choices: toArray(question.contents).map(content => content.text)
          }
        };
      },
      getAnswerChoices: (question: questionType) => {
        return question.contents.map(choice => {
          return { display: choice.text, value: choice.text };
        });
      },
      comperators: [
        Comperators.multiSelectContain,
        Comperators.multiSelectNotContain,
        Comperators.is,
        Comperators.isNot
      ],
      getPipedAnswerString: (
        answer: Array<pictureChoiceAnswerType>
      ): string => {
        return toArray(answer)
          .map(choice => choice.choiceContent)
          .join(" and ");
      }
    },
    Date: {
      typeName: DATE,
      iconClassName: "fa-calendar",
      formBuilderUIComponent: UI_Date,
      formUIComponent: UI_Date,
      defaultResponse: [],
      defaultContent: [""],
      delaySubmit: false,
      displayName: "Date",
      config: ["Required"],
      operations: [operation.image, operation.dateFormat],
      validators: ["date"],
      chart: MultichoiceChart,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (formData: Array<string>) =>
        (formData && formData[0]) || "",
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "ShortText",
          config: {}
        };
      },
      getAnswerChoices: (question: questionType) => {
        return question.contents.map(choice => {
          return { display: choice.text, value: choice.text };
        });
      },
      comperators: [
        Comperators.multiSelectContain,
        Comperators.multiSelectNotContain,
        Comperators.is,
        Comperators.isNot
      ],
      getPipedAnswerString: (answer: Array<string>): string => {
        return toArray(answer)[0];
      }
    },
    OpinionScale: {
      typeName: OPINION_SCALE,
      iconClassName: "fa-sliders",
      formBuilderUIComponent: UI_OpinionScale,
      formUIComponent: UI_OpinionScale,
      defaultResponse: "",
      delaySubmit: false,
      displayName: "Opinion Scale",
      config: ["Required"],
      operations: [
        operation.image,
        operation.jumpLogic,
        operation.score,
        operation.scalerSteps,
        operation.scalerLeftUnit,
        operation.scalerRightUnit
      ],
      validators: [],
      chart: MultichoiceChart,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (formData: number) => formData,
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "Rating",
          config: {}
        };
      },
      getAnswerChoices: (question: questionType) => {
        if (question.contents && question.contents.steps) {
          return explode(question.contents.steps).map(choice => {
            return { display: choice, value: choice };
          });
        } else {
          return explode(11).map(choice => {
            return { display: choice, value: choice };
          });
        }
      },
      comperators: [
        Comperators.ratingIs,
        Comperators.ratingIsNot,
        Comperators.ratingLessThan,
        Comperators.ratingMoreThan
      ],
      getPipedAnswerString: (answer: string): string => {
        return answer;
      }
    },
    Rating: {
      typeName: RATING,
      iconClassName: "fa-star",
      formBuilderUIComponent: UI_Rating,
      formUIComponent: UI_Rating,
      defaultResponse: "",
      delaySubmit: false,
      displayName: "Rating",
      config: ["Required"],
      operations: [
        operation.outcomeMapping,
        operation.image,
        operation.jumpLogic,
        operation.score
      ],
      validators: [],
      chart: RatingChart,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (formData: number) => formData,
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "Rating",
          config: {}
        };
      },
      getAnswerChoices: (question: questionType) => {
        return [
          { display: "None", value: 0 },
          { display: "⭐", value: 1 },
          { display: "⭐⭐", value: 2 },
          { display: "⭐⭐⭐", value: 3 },
          { display: "⭐⭐⭐⭐", value: 4 },
          { display: "⭐⭐⭐⭐⭐", value: 5 }
        ];
      },
      comperators: [
        Comperators.is,
        Comperators.isNot,
        Comperators.ratingLessThan,
        Comperators.ratingMoreThan
      ],
      getPipedAnswerString: (answer: number): string => {
        return explode(answer)
          .map(_ => "⭐")
          .join("");
      }
    },
    FileUpload: {
      typeName: FILEUPLOAD,
      iconClassName: "fa-file",
      formBuilderUIComponent: UI_FileUpload,
      formUIComponent: UI_FileUpload,
      defaultResponse: "",
      delaySubmit: false,
      displayName: "File Upload",
      config: ["Required"],
      operations: [
        operation.image,
        operation.jumpLogic,
        operation.imageCompression
      ],
      validators: ["fileSize"],
      chart: null,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (formData: submitContentType) => formData,
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "FileUpload",
          config: {}
        };
      },
      getAnswerChoices: (question: questionType) => {
        return [{ display: "uploaded", value: "uploaded" }];
      },
      comperators: [Comperators.fileIs]
    },
    YesNo: {
      typeName: YESNO,
      iconClassName: "fa-thumbs-o-up",
      formBuilderUIComponent: UI_YesNo,
      formUIComponent: UI_YesNo,
      defaultResponse: "",
      delaySubmit: false,
      displayName: "Yes/No",
      config: ["Required"],
      operations: [
        operation.image,
        operation.jumpLogic,
        operation.score,
        operation.outcomeMapping
      ],
      validators: [],
      chart: MultichoiceChart,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (formData: "yes" | "no") => formData,
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "YesNo",
          config: {}
        };
      },
      getAnswerChoices: (question: questionType) => {
        return [
          { display: "yes", value: "yes" },
          { display: "no", value: "no" }
        ];
      },
      comperators: [Comperators.is, Comperators.isNot],
      getPipedAnswerString: (answer: string): string => {
        return answer;
      }
    },
    Email: {
      typeName: EMAIL,
      iconClassName: "fa-envelope",
      formBuilderUIComponent: UI_Email,
      formUIComponent: UI_Email,
      defaultResponse: "",
      delaySubmit: false,
      displayName: "Email",
      config: ["Required"],
      operations: [operation.image, operation.jumpLogic],
      validators: ["email"],
      chart: null,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (formData: string) => formData,
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "Email",
          config: {}
        };
      },
      getAnswerChoices: null,
      comperators: [
        Comperators.contain,
        Comperators.notContain,
        Comperators.textStartWith,
        Comperators.textEndWith
      ],
      getPipedAnswerString: (answer: string): string => {
        return answer;
      }
    },

    Statement: {
      typeName: STATEMENT,
      iconClassName: "fa-comment",
      formBuilderUIComponent: UI_Statement,
      formUIComponent: UI_Statement,
      defaultResponse: "",
      delaySubmit: false,
      displayName: "Statement",
      config: ["Required"],
      operations: [operation.image, operation.jumpLogic],
      validators: [],
      chart: null,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (formData: string) => formData,
      toWisptableColumnSchema: null,
      getAnswerChoices: null
    },
    WelcomePage: {
      typeName: WELCOMEPAGE,
      iconClassName: "fa-gift",
      formBuilderUIComponent: UI_WelcomePage,
      formUIComponent: UI_WelcomePage,
      defaultResponse: "",
      delaySubmit: false,
      displayName: "Welcome page",
      config: ["Required"],
      operations: [operation.image, operation.jumpLogic],
      validators: [],
      chart: null,
      titlePlaceHolder: "Type your greeting message here...",
      onlyOneQuestionPerForm: true,
      toWisptableData: (formData: string) => formData,
      toWisptableColumnSchema: null,
      getAnswerChoices: null
    },
    ThankyouPage: {
      typeName: THANKYOUPAGE,
      iconClassName: "fa-external-link-square",
      displayName: "Thankyou page",
      toWisptableData: null,
      toWisptableColumnSchema: null,
      onlyOneQuestionPerForm: false,
      getAnswerChoices: null,
      operations: [
        operation.image,
        operation.thankyouPageDisplayLogic,
        operation.socialShare
      ]
    },
    OutcomePage: {
      typeName: OUTCOMEPAGE,
      iconClassName: "fa-external-link-square",
      displayName: "Outcome Page",
      toWisptableData: null,
      toWisptableColumnSchema: null,
      onlyOneQuestionPerForm: false,
      getAnswerChoices: null,
      hideInQuestionSelector: true,
      operations: [
        operation.maxOutcome2Display,
        operation.image,
        operation.socialShare,
        operation.outcomeLayout
      ]
    },
    CodeBlock: {
      typeName: CODEBLOCK,
      iconClassName: "fa-code",
      formBuilderUIComponent: UI_CodeBlock,
      formUIComponent: UI_CodeBlock,
      defaultResponse: "",
      delaySubmit: false,
      displayName: "Code",
      config: ["Required"],
      operations: [operation.image],
      vablidators: [],
      chart: null,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (formData: string) => formData,
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "CodeBlock",
          config: {}
        };
      },
      getAnswerChoices: null
    },
    Subscription: {
      typeName: SUBSCRIPTION,
      iconClassName: "fa-money",
      formBuilderUIComponent: UI_Subscription,
      formUIComponent: UI_Subscription,
      defaultResponse: "",
      delaySubmit: false,
      displayName: "Subscription",
      operations: [operation.image, operation.availableSubscriptionPlans],
      config: ["Required"],
      validators: ["paymentCard"],
      chart: null,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: true,
      toWisptableData: (formData: { name: string, planID: string }) =>
        formData.name,
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "ShortText",
          config: { choices: question.contents }
        };
      }
    },
    Payment: {
      typeName: PAYMENT,
      iconClassName: "fa-credit-card",
      formBuilderUIComponent: UI_Payment,
      formUIComponent: UI_Payment,
      defaultResponse: "",
      delaySubmit: false,
      displayName: "Payment",
      operations: [
        operation.image,
        operation.dynamicPricing,
        operation.currency
      ],
      config: ["Required"],
      validators: ["paymentCard"],
      chart: null,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: true,
      toWisptableData: (formData: string) => formData,
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "ShortText",
          config: { choices: question.contents }
        };
      }
    },
    DropDown: {
      typeName: DROPDOWN,
      iconClassName: "fa-caret-down",
      formBuilderUIComponent: BuilderDropdown,
      formUIComponent: UI_DropDown,
      defaultResponse: [],
      defaultContent: [""],
      delaySubmit: true,
      displayName: "Dropdown",
      config: ["Required"],
      operations: [
        operation.outcomeMapping,
        operation.image,
        operation.jumpLogic,
        operation.score
      ],
      validators: [],
      chart: MultichoiceChart,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (
        formData: Array<{| index: number, choiceContent: string |}>
      ) => formData.map(choiceInfo => choiceInfo.choiceContent),
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "MultiSelect",
          config: { choices: question.contents }
        };
      },
      getAnswerChoices: (question: questionType) => {
        return question.contents.map(choice => {
          return { display: choice, value: choice };
        });
      },
      comperators: [Comperators.is, Comperators.isNot],
      getPipedAnswerString: (answer: Array<multichoiceAnswerType>): string => {
        return toArray(answer)
          .map(choice => choice.choiceContent)
          .join(" and ");
      }
    },
    HiddenField: {
      typeName: HIDDEN_FIELD,
      iconClassName: "fa-eye-slash",
      formBuilderUIComponent: UI_ShortText,
      formUIComponent: UI_ShortText,
      defaultResponse: "",
      delaySubmit: false,
      displayName: "Hidden Field",
      config: ["Required"],
      operations: [operation.image, operation.jumpLogic],
      validators: [],
      chart: null,
      titlePlaceHolder: "Type your question here...",
      onlyOneQuestionPerForm: false,
      toWisptableData: (formData: string) => formData,
      toWisptableColumnSchema: (
        question: questionType
      ): tableSchemaColumnType => {
        return {
          id: question.question_id,
          name: question.title,
          type: "ShortText",
          config: {}
        };
      },
      getAnswerChoices: null,
      comperators: [
        Comperators.contain,
        Comperators.notContain,
        Comperators.textStartWith,
        Comperators.textEndWith
      ],
      getPipedAnswerString: (answer: any): string => {
        return String(answer);
      },
      hideInQuestionSelector: true
    }
  };

  this.validators = {
    require: validate_require,
    email: validate_email,
    fileSize: validateFileSize,
    date: validateDate,
    maxWordCount: validateWordCount,
    paymentCard: validatePaymentCard
  };

  this.styles = {
    background: {
      color: "#ffffff",
      type: "color"
    },
    answer: "#555555",
    text: "#555555",
    button: "#007aff", // Deprecated
    buttonColor: "#007aff"
  };
}

/*
    Validators take in answer and question type
    return "", if passed validation
    return error message if not passed validator
*/
function validate_require(answer, question) {
  if (
    question === QuestionTypeEnum.Subscription ||
    question === QuestionTypeEnum.Payment
  ) {
    if (!hasValidStripeToken()) {
      return "Oops, you need to enter a valid payment method.";
    }
  }
  if (answer == "" || (Array.isArray(answer) && answer.length == 0)) {
    return "Oops, you need to complete this question.";
  } else {
    return "";
  }
}

function validate_email(answer, question) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const is_valid = re.test(answer);
  if (is_valid) {
    return "";
  } else {
    logErrorInfo(
      "email_validation_failed",
      JSON.stringify({
        email: answer,
        encodedRep: encodeURIComponent(answer)
      })
    );
    return "Hm.. This doesn't look like a valid email";
  }
}

function validateNumber(answer, question) {}

export function validateFileSize(answer: any, question_type: any) {
  // Don't add check for pro plan
  if (isFormFromPaidPlan()) {
    return "";
  }
  const fileSize = answer.fileSize;
  const fiveMb = 5000000;
  if (fileSize > fiveMb) {
    return "File size should be less than 5MB";
  } else {
    return "";
  }
}

export function validateDate(answer: any, question: any) {
  const errorMessage = "Hm.. This doesn't look like a valid date";
  if (!answer) return "";
  if (isNonEmptyString(answer[0])) {
    const parsed = answer[0].split("-");
    if (parsed.length !== 3) {
      return errorMessage;
    }
    if (
      !isStringNumericOrNull(parsed[0]) ||
      !isStringNumericOrNull(parsed[1]) ||
      !isStringNumericOrNull(parsed[2])
    ) {
      return errorMessage;
    }
  }
  return "";
}

export function validatePaymentCard(answer: any, question: any) {
  const stripeError = getStripeValidationError();
  if (isNullOrUndefined(stripeError)) {
    return "";
  } else {
    return nonNullOrThrow(stripeError).message;
  }
}

export function validateWordCount(answer: any, question: any) {
  const errorMessage =
    "Hm.. You have exceeded the word limit for this question";
  if (!answer) return "";
  if (isNonEmptyString(answer[0])) {
    if (
      question &&
      question.contents &&
      isNumber(question.contents.maxWordLimit)
    ) {
      if (answer.split(" ").length > question.contents.maxWordLimit) {
        return errorMessage;
      }
    }
  }
  return "";
}

//Question Type names
export const MULTICHOICES = "MultiChoices";
export const RATING = "Rating";
export const DROPDOWN = "DropDown";
export const SHORTTEXT = "ShortText";
export const NUMBER = "Number";
export const EMAIL = "Email";
export const LONGTEXT = "LongText";
export const STATEMENT = "Statement";
export const WELCOMEPAGE = "WelcomePage";
export const FILEUPLOAD = "FileUpload";
export const YESNO = "YesNo";
export const THANKYOUPAGE = "ThankyouPage";
export const CODEBLOCK = "CodeBlock";
export const PAYMENT = "Payment";
export const SUBSCRIPTION = "Subscription";
export const PICTURE_CHOICE = "PictureChoice";
export const OPINION_SCALE = "OpinionScale";
export const DATE = "Date";
export const HIDDEN_FIELD = "HiddenField";
export const OUTCOMEPAGE = "OutcomePage";

export const QuestionTypeEnum = {
  MultiChoices: "MultiChoices",
  Rating: "Rating",
  DropDown: "DropDown",
  ShortText: "ShortText",
  Email: "Email",
  LongText: "LongText",
  Statement: "Statement",
  WelcomePage: "WelcomePage",
  YesNo: "YesNo",
  ThankyouPage: "ThankyouPage",
  FileUpload: "FileUpload",
  CodeBlock: "CodeBlock",
  Payment: "Payment",
  PictureChoice: "PictureChoice",
  OpinionScale: "OpinionScale",
  HiddenField: "HiddenField",
  Subscription: "Subscription",
  OutcomePage: "OutcomePage"
};
export type QuestionTypes = $Values<typeof QuestionTypeEnum>;

/* questionConfig type is legacy setting that only supports boolean. 
We now use operations to achieve new settings. */
export type configTypes =
  | "Add other"
  | "Multiple Selection"
  | "Required"
  | "paymentAmount";
export type questionConfig = {
  [configTypes]: boolean
};

export type questionType = {|
  type: QuestionTypes,
  title: string,
  config: questionConfig,
  contents: any,
  isRequired: boolean,
  validators: Array<string>,
  description: string,
  question_id: string,
  resultPageWidth: number,
  image?: string,
  media?: questionMediaType,
  jumpLogic?: logicJumpType,
  outcomeDisplayLogic?: ToutcomeDisplayLogic,
  onlyOneQuestionPerForm: boolean,
  scoreCalculations?: questionScoreCalculationsType
|};

export type formDataType = {|
  publisher_name: ?string,
  created_at: string,
  form_id: number,
  id: number,
  last_edited_question: number,
  questions: Array<questionType>,
  styles: formStyleType,
  updated_at: string,
  form_plan: formPlan,
  configurations?: { hiddenFieldVariables?: hiddenFieldVariablesType },
  publisherID: number,
  form_type: ?TFormType
|};

export type AnswerType = {|
  answer: any,
  question_id: string,
  title: string,
  type: QuestionTypes
|};
export type AnswerReduxStateType = {|
  answers: Array<AnswerType>,
  answers_posted: boolean,
  currentQuestionNumber: number,
  form_id: string,
  invalid_answers: { [number]: ?boolean },
  publisher_name: string,
  questions: any,
  styles: formStyleType,
  submit_attempted: boolean,
  submitting: true,
  time_to_complete: number
|};

export type questionScoreCalculationsType = Array<questionScoreCalculationType>;

export type questionScoreCalculationOperationType = "add" | "subtract";
export type questionScoreCalculationType = {|
  targetAnswer: number | string,
  operation: questionScoreCalculationOperationType,
  score: ?number
|};

// @flow
import { getUserName, getUserID, isPaidUser } from "../UserInfo";

export function isDeveloper() {
  const developerIDs = [22, 40, 5, 109, 2, 3];
  const userID = getUserID();
  return developerIDs.includes(userID);
}

export function userIDMatch(targetUserID: number) {
  return targetUserID === getUserID();
}

//@flow
import {
  type notificationType,
  type Props as notificationProps
} from "../../../../Component/Notification";
import { getNotifications } from "./selectors";

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

type showNotificationPayloadType = {|
  type: typeof SHOW_NOTIFICATION,
  notifications: Array<{|
    ...notificationProps,
    id: string
  |}>
|};

export function showNotification(
  notificationType: notificationType,
  title: string,
  description: string,
  actionName?: string,
  onActionClick?: () => void
) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const currentNotifications: Array<{|
      ...notificationProps,
      id: string
    |}> = getNotifications(getState().Notifications);
    const notificationID: string = String(Date.now());
    dispatch({
      type: SHOW_NOTIFICATION,
      notifications: [
        ...currentNotifications,
        {
          type: SHOW_NOTIFICATION,
          title: title,
          description: description,
          notificationType: notificationType,
          id: notificationID,
          actionName: actionName,
          onActionClick: onActionClick
        }
      ]
    });
    //auto close notification after 1000 ms
    setTimeout(() => {
      dispatch(closeNotification(notificationID));
    }, 6000);
  };
}

type closeNotificationPayloadType = {|
  type: typeof CLOSE_NOTIFICATION,
  notifications: Array<{|
    ...notificationProps,
    id: string
  |}>
|};
export function closeNotification(id: string) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const currentNotifications: Array<{|
      ...notificationProps,
      id: string
    |}> = getNotifications(getState().Notifications);
    dispatch({
      type: CLOSE_NOTIFICATION,
      notifications: currentNotifications.filter(
        notification => notification.id !== id
      )
    });
  };
}

export type actionTypes =
  | showNotificationPayloadType
  | closeNotificationPayloadType;

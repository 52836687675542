//@flow
import * as React from "react";
import Linkify from "react-linkify";
import { isSmallMobileScreen } from "../../../Library/Window";
import "./style.css";

export type Props = {|
  color?:
    | "darkGray"
    | "lightBlue"
    | "blue"
    | "lightGray"
    | "Grey"
    | "white"
    | "black"
    | "red",
  hexColor?: string,
  disableNewLine?: boolean,
  fontFamily?: ?string,
  size?: "xxs" | "xs" | "sm" | "md" | "lg" | "xl",
  weight?: "normal" | "medium" | "bold" | "light",
  alignment?: "left" | "center" | "right",
  className?: string,
  children: React.Node,
  overflow?: "wrap" | "ellipsis",
  parseLink?: boolean,
  disableSelect?: boolean,
  dangerous_style?: Object,
  display?: "inline",
  fontStyle?: "italic"
|};

class UICoreText extends React.Component<Props> {
  static isSmallMobileScreen = isSmallMobileScreen();

  _getFontSoze = (): string => {
    switch (this.props.size) {
      case "xxs":
        return "12px";
      case "xs":
        return "14px";
      case "sm":
        return "16px";
      case "md":
        return "18px";
      case "lg":
        return UICoreText.isSmallMobileScreen ? "20px" : "22px";
      case "xl":
        return UICoreText.isSmallMobileScreen ? "22px" : "24px";
      case "xxl":
        return UICoreText.isSmallMobileScreen ? "24px" : "28px";
      default:
        return "16px";
    }
  };

  _getColor = () => {
    if (this.props.hexColor) {
      return this.props.hexColor;
    }
    switch (this.props.color) {
      case "darkGray":
        return "#6D6D6D";
      case "lightGray":
        return "#DBDBDB";
      case "black":
        return "#434343";
      case "lightBlue":
        return "#317cf6";
      case "blue":
        return "#406aff";
      case "Grey":
        return "#A3A3A3";
      case "red":
        return "#ff0000";
      case "white":
        return "#ffffff";
      default:
        return "#6D6D6D";
    }
  };

  _getNewLineStype = () => {
    if (this.props.disableNewLine === true) {
      return {};
    } else {
      return {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word"
      };
    }
  };

  _getStyle = () => {
    return {
      ...this._getNewLineStype(),
      fontSize: this._getFontSoze(),
      color: this._getColor(),
      userSelect: this.props.disableSelect ? "none" : "default",
      display: this.props.display,
      fontFamily: this._getFontFamily(),
      fontStyle: this.props.fontStyle,
      ...this.props.dangerous_style
    };
  };

  _getAlignmentClass = () => {
    switch (this.props.alignment) {
      case "center":
        return "UICoreText-center";
      case "left":
        return "UICoreText-left";
      case "right":
        return "UICoreText-right";
      default:
        return "UICoreText-left";
    }
  };

  _getOverflowClass = () => {
    switch (this.props.overflow) {
      case "wrap":
        return "UICoreText-wrap";

      case "ellipsis":
        return "UICoreText-ellipsis";
      default:
        return "UICoreText-ellipsis";
    }
  };

  _getFontWeightClass = () => {
    switch (this.props.weight) {
      case "light":
        return "UICoreTex-light";
      case "bold":
        return "UICoreTex-bold";
      case "medium":
        return "UICoreTex-medium";
      case "regular":
        return "UICoreTex-regular";
      default:
        return "UICoreTex-normal";
    }
  };

  _getFontFamily = () => {
    if (this.props.fontFamily) {
      return `${this.props.fontFamily}, Roboto`;
    }
    // Default font
    return "Roboto";
  };

  render() {
    const componentDecorator = (href, text, key) => (
      <a
        style={{
          color: this._getColor(),
          "font-style": "italic",
          "text-decoration": "underline"
        }}
        href={href}
        key={key}
        target="_blank"
      >
        {text}
      </a>
    );
    return (
      <div
        style={this._getStyle()}
        className={`UICoreText ${this._getAlignmentClass()} ${this._getFontWeightClass()} ${
          this.props.className ? this.props.className : ""
        } ${this._getOverflowClass()}`}
      >
        {this.props.parseLink ? (
          <Linkify componentDecorator={componentDecorator}>
            {this.props.children}
          </Linkify>
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}

export default UICoreText;

//@flow
import * as React from "react";
import { isNullOrUndefined } from "../../../Library/Util";
import "./style.css";

export type size = "xl" | "lg" | "md" | "sm" | "xm" | "xxm" | "0 auto" | "auto";
type alignment = "center" | "start" | "end" | "between";
export type colorType =
  | "blue"
  | "white"
  | "lightGrey"
  | "Grey"
  | "skyBlue"
  | "lightBlue"
  | "lightRed"
  | "lightOrgange"
  | "lightYellow"
  | "lightGreen"
  | "lightLime"
  | "lightPurple";
export type Props = {|
  borderWidth?: number,
  borderColor?: string,
  borderRadius?: string,
  color?: colorType,
  hexColor?: string,
  children: React.Node,
  className?: string,
  contentEditable?: boolean,
  name?: string, //use to comment the functionality of Box
  alignItems?: alignment,
  flex?: number,
  justifyContent?: alignment,
  margin?: size,
  horizontalMargin?: size,
  marginLeft?: size,
  marginRight?: size,
  verticalMargin?: size,
  marginTop?: size,
  marginBottom?: size,
  padding?: size,
  horizontalPadding?: size,
  paddingLeft?: size,
  paddingRight?: size,
  verticalPadding?: size,
  paddingTop?: size,
  paddingBottom?: size,
  position?: "absolute" | "static" | "fixed" | "relative" | "sticky",
  height?: number | string,
  maxWidth?: number | string,
  maxHeight?: number | string,
  minHeight?: number | string,
  width?: number | string,
  autoWidth?: "fitContent",
  top?: string,
  right?: string,
  bottom?: string,
  left?: string,
  hoverable?: boolean,
  zIndex?: number,
  disabled?: boolean,
  wrap?: "wrap",
  shape?: "rounded" | "topRounded" | "circle",
  direction?: "row" | "column",
  boxShadow?: boolean,
  overflowX?: "visible" | "hidden" | "scroll" | "auto",
  overflowY?: "visible" | "hidden" | "scroll" | "auto",
  onClick?: (SyntheticInputEvent<HTMLDivElement>) => void,
  onMouseDown?: (SyntheticInputEvent<HTMLDivElement>) => void,
  innerRef?: (?HTMLElement) => void,
  dangerous_style?: Object,
  onMouseOver?: (SyntheticInputEvent<HTMLDivElement>) => void,
  onMouseLeave?: (SyntheticInputEvent<HTMLDivElement>) => void,
  onContextMenu?: (SyntheticInputEvent<HTMLDivElement>) => void
|};

class UICoreBox extends React.Component<Props> {
  _innerNode: ?HTMLElement;

  _getPixel = (size?: size): string => {
    switch (size) {
      case "xxm":
        return "4px";
      case "xm":
        return "8px";
      case "sm":
        return "16px";
      case "md":
        return "24px";
      case "lg":
        return "48px";
      case "xl":
        return "64px";
      case "0 auto":
        return "0 auto";
      case "auto":
        return "auto";
      default:
        return "";
    }
  };

  _getMargin = (size?: size, fallbackSize?: size) => {
    if (size) {
      return this._getPixel(size);
    } else if (fallbackSize) {
      return this._getPixel(fallbackSize);
    } else {
      return this._getPixel(this.props.margin);
    }
  };

  _getPadding = (size?: size, fallbackSize?: size) => {
    if (size) {
      return this._getPixel(size);
    } else if (fallbackSize) {
      return this._getPixel(fallbackSize);
    } else {
      return this._getPixel(this.props.padding);
    }
  };

  _getBorderRadius = () => {
    switch (this.props.borderRadius) {
      case "lg":
        return "5px";
      default:
        return null;
    }
  };

  _getAlignItens = (alignment?: alignment) => {
    switch (alignment) {
      case "center":
        return "center";
      case "start":
        return "flex-start";
      case "end":
        return "flex-end";
      case "between":
        return "space-between";

      default:
        break;
    }
  };

  _getPositionClass = () => {
    switch (this.props.position) {
      case "absolute":
        return "UICoreBox-absolute";

      case "relative":
        return "UICoreBox-relative";
      case "fixed":
        return "UICoreBox-fixed";
      case "static":
        return "UICoreBox-static";
      case "sticky":
        return "UICoreBox-sticky";

      default:
        return "UICoreBox-static";
    }
  };

  _getHoverableClass = () => {
    if (isNullOrUndefined(this.props.hoverable)) {
      return "";
    }

    if (this.props.hoverable) {
      switch (this.props.color) {
        case "skyBlue":
          return "UICoreBox-skyBlue-hover";

        default:
          return "UICoreBox-hover";
      }
    } else {
      return "";
    }
  };

  _getDisabledClass = () => {
    return this.props.disabled ? "UICoreBox-disabled" : "";
  };

  _getAutoWidth = () => {
    switch (this.props.autoWidth) {
      case "fitContent":
        return "fit-content";
      default:
        return null;
    }
  };

  _getStyle = () => {
    return {
      flex: this.props.flex,
      margin: this._getPixel(this.props.margin),
      padding: this._getPixel(this.props.padding),
      marginLeft: this._getMargin(
        this.props.marginLeft,
        this.props.horizontalMargin
      ),
      marginRight: this._getMargin(
        this.props.marginRight,
        this.props.horizontalMargin
      ),
      marginTop: this._getMargin(
        this.props.marginTop,
        this.props.verticalMargin
      ),
      marginBottom: this._getMargin(
        this.props.marginBottom,
        this.props.verticalMargin
      ),
      paddingLeft: this._getPadding(
        this.props.paddingLeft,
        this.props.horizontalPadding
      ),
      paddingRight: this._getPadding(
        this.props.paddingRight,
        this.props.horizontalPadding
      ),
      paddingTop: this._getPadding(
        this.props.paddingTop,
        this.props.verticalPadding
      ),
      paddingBottom: this._getPadding(
        this.props.paddingBottom,
        this.props.verticalPadding
      ),
      alignItems: this._getAlignItens(this.props.alignItems),
      justifyContent: this._getAlignItens(this.props.justifyContent),
      height: this.props.height,
      maxHeight: this.props.maxHeight,
      maxWidth: this.props.maxWidth,
      minHeight: this.props.minHeight,
      top: this.props.top ? this.props.top : null,
      right: this.props.right ? this.props.right : null,
      bottom: this.props.bottom ? this.props.bottom : null,
      left: this.props.left ? this.props.left : null,
      flexDirection: this.props.direction ? this.props.direction : "column",
      width: this.props.width ? this.props.width : this._getAutoWidth(),
      backgroundColor: this._getBackgroundColor(),
      overflowX: this.props.overflowX ? this.props.overflowX : null,
      overflowY: this.props.overflowY ? this.props.overflowY : null,
      ["flex-wrap"]: this.props.wrap ? this.props.wrap : null,
      boxShadow: this.props.boxShadow
        ? "rgba(152, 152, 152, 50) 0px 0px 15px 0px"
        : null,
      borderWidth: this.props.borderColor
        ? this.props.borderWidth || "1px"
        : null,
      borderColor: this.props.borderColor,
      borderStyle: this.props.borderColor ? "solid" : null,
      borderRadius: this._getBorderRadius(),
      zIndex: this.props.zIndex,
      ...this.props.dangerous_style
    };
  };

  _getBackgroundColor = () => {
    if (this.props.hexColor) {
      return this.props.hexColor;
    }
    switch (this.props.color) {
      case "white":
        return "#ffffff";

      case "lightGrey":
        return "#ECECEC";

      case "Grey":
        return "#A5A5A5";

      case "lightBlue":
        return "#88C8FE";

      case "skyBlue":
        return "#ecf0ff";

      case "lightRed":
        return "#FFEAF0";
      case "lightOrgange":
        return "#FFE6C3";
      case "lightYellow":
        return "#FDFCBE";
      case "lightGreen":
        return "#C1F8B8";
      case "lightLime":
        return "#C9FDDB";
      case "lightPurple":
        return "#E8E0FD";
      case "blue":
        return "#406aff";

      default:
        return null;
    }
  };

  _getBorderClass = () => {
    switch (this.props.shape) {
      case "rounded":
        return "UICoreBox-rounded";
      case "topRounded":
        return "UICoreBox-topRounded";
      case "circle":
        return "UICoreBox-circle";
      default:
        return "";
    }
  };

  render() {
    return (
      <div
        onContextMenu={this.props.onContextMenu}
        onMouseDown={this.props.onMouseDown}
        contentEditable={this.props.contentEditable}
        onMouseOver={this.props.onMouseOver}
        onMouseLeave={this.props.onMouseLeave}
        onClick={this.props.onClick}
        style={this._getStyle()}
        className={
          "UICoreBox " +
          `${
            this.props.className ? this.props.className : ""
          } ${this._getPositionClass()} ${this._getBorderClass()} ${this._getHoverableClass()} ${this._getDisabledClass()}`
        }
        ref={this.props.innerRef}
      >
        {this.props.children}
      </div>
    );
  }
}

export default UICoreBox;

//@flow
import * as React from "react";
import "./style.css";
import { logResultPageEvent } from "../../../Library/Logger";

type Props = {|
  href: string,
  children: React.Node,
  dangerous_style?: Object,
  color?: string,
  same_tab?: boolean,
  logger?: string
|};
type State = {||};
class UICoreLink extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _getStyle = () => {
    return { color: this._getColor(), ...this.props.dangerous_style };
  };

  _getColor = () => {
    switch (this.props.color) {
      case "darkGray":
        return "#6D6D6D";
      case "lightGray":
        return "#DBDBDB";
      case "black":
        return "#434343";
      case "blue":
        return "#406aff";
      case "Grey":
        return "#A3A3A3";
      default:
        return "inherit";
    }
  };

  render() {
    if (this.props.same_tab === false) {
      return (
        <a
          target="_blank"
          className="UICoreLink"
          href={this.props.href}
          style={this._getStyle()}
          onClick={() => {
            if (this.props.logger) {
              if (this.props.logger == "dropoffInsightDocumentationClicked") {
                logResultPageEvent("dropoffInsightDocumentationClicked");
              } else if (
                this.props.logger == "userInsightDocumentationClicked"
              ) {
                logResultPageEvent("userInsightDocumentationClicked");
              }
            }
          }}
        >
          {this.props.children}
        </a>
      );
    } else {
      return (
        <a
          className="UICoreLink"
          href={this.props.href}
          style={this._getStyle()}
          onClick={() => {
            if (this.props.logger) {
              if (this.props.logger == "dropoffInsightDocumentationClicked") {
                logResultPageEvent("dropoffInsightDocumentationClicked");
              } else if (
                this.props.logger == "userInsightDocumentationClicked"
              ) {
                logResultPageEvent("userInsightDocumentationClicked");
              }
            }
          }}
        >
          {this.props.children}
        </a>
      );
    }
  }
}
export default UICoreLink;

//@flow
import BaseFormConfig from "./BaseFormConfig";
import { IFormConfig } from "./IFormConfig";

export default class ClassicFormConfig
  extends BaseFormConfig
  implements IFormConfig
{
  displayName = "Form";
  availableQuestionOperations = [];
  creationModalConfig = {
    title: "Create a Beautiful Wispform",
    inputPlaceholder: "new wispform",
    description: `To begin creating a beautiful form, survey, or quiz, please first give it a name 😊`,
    enableTemplate: true
  };
}

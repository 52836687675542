//@flow
import * as React from "react";
import "./style.css";
import {
  UICoreBox,
  UICoreText,
  UICoreImage,
  UICoreButton,
  UICoreReorderable
} from "../../../UICore";
import WithStyles from "../../../../Helper_HOC/WithStyles";
import { type formStyleType } from "../../../../FlowTypes/wispformStyleTypes";
import { connect } from "react-redux";
import {
  updateQuestionContent,
  updateQuestionContentWithouPersistence
} from "../../../../Pages/FormBuilderPage/States/actions";
import {
  removeElemenent,
  replace,
  toArray,
  swap,
  safeGet,
  isNonEmptyArray,
  isNull
} from "../../../../Library/Util";
import GFC_Outcome_Instance, {
  type pictureChoice
} from "./GFC_Outcome_Instance";
import { type questionConfig } from "../../../../Pages/QuestionTypes";
import { type TOutcomeCTAData, type TOutcomeData } from "../../type";
import uniqid from "uniqid";
import { type TGenericContentOutcomeLayout } from "../../type";

export type choiceAnswerType = {|
  choiceContent: string,
  index: number
|};

type answerType = Array<choiceAnswerType>;

type questionAnswerType = {|
  answer: Array<answerType>,
  question_id: string,
  title: string,
  type: string
|};

type Props = {|
  contents: Array<TOutcomeData>,
  layout: ?TGenericContentOutcomeLayout,
  answerPrimaryColor: string,
  answerSecondaryColor: string,
  styles: formStyleType,
  config: questionConfig,
  pageNumber: number,
  isInBuilder: boolean,
  isCurrentQuestion: boolean,
  updatePictureChoices: (Array<TOutcomeData>) => void,
  updatePictureChoicesWithouPersistence: (Array<TOutcomeData>) => void,
  onQuestionComplete?: (?answerType) => mixed,
  onQuestionUpdate?: (?answerType, boolean) => mixed,
  answer?: questionAnswerType
|};
type State = {||};

class GFC_Outcome_Group extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _addOutcome = () => {
    this.props.updatePictureChoices([
      ...this.props.contents,
      {
        id: uniqid.time(),
        title: "",
        media: ""
      }
    ]);
  };

  _isHorizontalLayout() {
    return isNull(this.props.layout) || this.props.layout === "horizontal";
  }

  _renderAddPictureChoiceButton = () => {
    return (
      <UICoreBox
        className="UI_PictureChoice-Add"
        padding="xm"
        width={this._isHorizontalLayout() ? "100%" : "50%"}
      >
        <UICoreBox
          shape="rounded"
          hexColor={this.props.answerSecondaryColor}
          padding="xm"
          height="100%"
          justifyContent="center"
          alignItems="center"
          onClick={this._addOutcome}
        >
          <UICoreBox
            minHeight="150px"
            justifyContent="center"
            alignItems="center"
            padding="sm"
          >
            <UICoreBox name="plusIcon">
              <i
                style={{ color: this.props.styles.answer }}
                className="ion-plus UI_PictureChoice-Plus"
              />
            </UICoreBox>
            <UICoreBox>
              <UICoreText hexColor={this.props.styles.answer}>
                Add an outcome
              </UICoreText>
            </UICoreBox>
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  };

  _removePictureChoice = (index: number) => {
    this.props.updatePictureChoices(
      removeElemenent(this.props.contents, index)
    );
  };

  _updateChoiceText = (index: number, text: string) => {
    this.props.updatePictureChoices(
      replace(
        this.props.contents,
        { ...this.props.contents[index], title: text },
        index
      )
    );
  };

  _updateChoiceTextWithoutPersistence = (index: number, text: string) => {
    this.props.updatePictureChoicesWithouPersistence(
      replace(
        this.props.contents,
        { ...this.props.contents[index], title: text },
        index
      )
    );
  };

  _updateChoiceDescription = (index: number, description: string) => {
    this.props.updatePictureChoices(
      replace(
        this.props.contents,
        { ...this.props.contents[index], description: description },
        index
      )
    );
  };

  _updateChoiceDescriptionWithoutPersistence = (
    index: number,
    description: string
  ) => {
    this.props.updatePictureChoicesWithouPersistence(
      replace(
        this.props.contents,
        { ...this.props.contents[index], description: description },
        index
      )
    );
  };

  _updateChoicePic = (index: number, picUrl: string) => {
    this.props.updatePictureChoices(
      replace(
        this.props.contents,
        { ...this.props.contents[index], media: picUrl },
        index
      )
    );
  };

  _updateChoicePrimaryButton = (index: number, button: TOutcomeCTAData) => {
    this.props.updatePictureChoices(
      replace(
        this.props.contents,
        { ...this.props.contents[index], primaryCTA: button },
        index
      )
    );
  };

  _updateChoiceSecondaryButton = (index: number, button: TOutcomeCTAData) => {
    this.props.updatePictureChoices(
      replace(
        this.props.contents,
        { ...this.props.contents[index], secondaryCTA: button },
        index
      )
    );
  };

  _getMultiSelectAnswers = (index: number, text: string): answerType => {
    const selectedChoices = toArray(
      // $FlowFixMe
      safeGet(_ => this.props.answer.answer)
    );
    const isAlreadySelected = selectedChoices.some(
      answer => answer.index === index
    );
    if (isAlreadySelected) {
      return selectedChoices.filter(answer => answer.index !== index);
    } else {
      return [
        ...selectedChoices,
        {
          choiceContent: text,
          index: index
        }
      ];
    }
  };

  _onChoiceClick = (index: number, text: string) => {
    if (safeGet(_ => this.props.config["Multiple Selection"])) {
      this.props.onQuestionUpdate &&
        // $FlowFixMe
        this.props.onQuestionUpdate(
          this._getMultiSelectAnswers(index, text),
          true
        );
    } else {
      this.props.onQuestionComplete &&
        this.props.onQuestionComplete([{ choiceContent: text, index: index }]);
    }
  };

  _isSelected = (index: number): boolean => {
    if (this.props.isInBuilder) return false;
    return toArray(this.props.answer && this.props.answer.answer).some(
      (answer: choiceAnswerType) => answer.index === index
    );
  };

  _onSortEnd = (
    info: {
      oldIndex: number,
      newIndex: number,
      collection: Array<any>,
      isKeySorting: boolean
    },
    e: HTMLElement
  ) => {
    this.props.updatePictureChoices(
      swap(this.props.contents, info.oldIndex, info.newIndex)
    );
  };

  _getUICoreReorderableKey = () => {
    //we need to make sure UICoreReorderable is re executed after switch between questions and new pic choice is added
    return this.props.pageNumber + " " + toArray(this.props.contents).length;
  };

  render() {
    return (
      <UICoreBox
        className="UI_PictureChoice-Container"
        direction="row"
        width="100%"
      >
        {this.props.isInBuilder ? (
          <UICoreReorderable
            key={this._getUICoreReorderableKey()}
            direction="both"
            onSortEnd={this._onSortEnd}
            disableLast={true}
          >
            {[
              ...this.props.contents.map(
                (pictureChoice: TOutcomeData, index: number) => {
                  return (
                    <GFC_Outcome_Instance
                      onChoiceClick={() =>
                        this._onChoiceClick(index, pictureChoice.title)
                      }
                      layout={this.props.layout}
                      isInBuilder={this.props.isInBuilder}
                      key={index}
                      outcomeData={pictureChoice}
                      answerPrimaryColor={this.props.answerPrimaryColor}
                      answerSecondaryColor={this.props.answerSecondaryColor}
                      styles={this.props.styles}
                      removePictureChoice={() =>
                        this._removePictureChoice(index)
                      }
                      updateChoiceText={(text: string) =>
                        this._updateChoiceText(index, text)
                      }
                      updateChoiceTextWithoutPersistence={(text: string) =>
                        this._updateChoiceTextWithoutPersistence(index, text)
                      }
                      updateChoiceDescription={(text: string) =>
                        this._updateChoiceDescription(index, text)
                      }
                      updateChoiceDescriptionWithoutPersistence={(
                        text: string
                      ) =>
                        this._updateChoiceDescriptionWithoutPersistence(
                          index,
                          text
                        )
                      }
                      updateChoicePic={(imageUrl: string) =>
                        this._updateChoicePic(index, imageUrl)
                      }
                      updateChoicePrimaryButton={(button: TOutcomeCTAData) => {
                        this._updateChoicePrimaryButton(index, button);
                      }}
                      updateChoiceSecondaryButton={(
                        button: TOutcomeCTAData
                      ) => {
                        this._updateChoiceSecondaryButton(index, button);
                      }}
                      isSelected={false}
                    />
                  );
                }
              ),
              this._renderAddPictureChoiceButton()
            ]}
          </UICoreReorderable>
        ) : (
          this.props.contents.map(
            (pictureChoice: TOutcomeData, index: number) => {
              return (
                <GFC_Outcome_Instance
                  onChoiceClick={() =>
                    this._onChoiceClick(index, pictureChoice.title)
                  }
                  layout={this.props.layout}
                  isInBuilder={this.props.isInBuilder}
                  key={index}
                  outcomeData={pictureChoice}
                  choicePicUrl={pictureChoice.media}
                  choiceText={pictureChoice.title}
                  answerPrimaryColor={this.props.answerPrimaryColor}
                  answerSecondaryColor={this.props.answerSecondaryColor}
                  styles={this.props.styles}
                  removePictureChoice={() => this._removePictureChoice(index)}
                  updateChoiceText={(text: string) =>
                    this._updateChoiceText(index, text)
                  }
                  updateChoiceTextWithoutPersistence={(text: string) =>
                    this._updateChoiceTextWithoutPersistence(index, text)
                  }
                  updateChoicePic={(imageUrl: string) =>
                    this._updateChoicePic(index, imageUrl)
                  }
                  isSelected={this._isSelected(index)}
                />
              );
            }
          )
        )}
      </UICoreBox>
    );
  }
}

export default WithStyles(GFC_Outcome_Group);

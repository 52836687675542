//@flow
import * as React from "react";
import "./style.css";
import { UICoreText, UICoreBox } from "..";
import { isStringAllLetters } from "../../../Library/Util";
export type inputType = "numeric" | "letter";
type Props = {|
  size?: "xs" | "xxs" | "sm" | "md" | "lg" | "xl",
  value: ?string,
  clickToSelect?: boolean,
  autoFocus?: boolean,
  autoSelect?: boolean,
  disabled?: boolean,
  withBorder?: boolean,
  type?: inputType,
  backgroundColor?: "white",
  placeholder?: string,
  width?: number | string,
  validator?: string => string,
  onChange: (SyntheticInputEvent<HTMLInputElement>, value: string) => void,
  onBlur?: (SyntheticInputEvent<HTMLInputElement>) => void,
  onKeyUp?: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
  onCopy?: () => void,
  getInputRef?: (e: ?HTMLInputElement) => void
|};
type State = {|
  errorMessage: ?string
|};
class UICoreInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    errorMessage: null
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.props.autoFocus) {
        this._inputNode && this._inputNode.focus();
      }
      if (this.props.autoSelect) {
        this._inputNode && this._inputNode.select();
      }
    }, 100);
  }

  _inputNode: ?HTMLInputElement = null;

  _getFontSoze = (): string => {
    switch (this.props.size) {
      case "xs":
        return "14px";
      case "xxs":
        return "12px";
      case "sm":
        return "16px";
      case "md":
        return "18px";
      case "lg":
        return "22px";
      case "xl":
        return "26px";
      default:
        return "16px";
    }
  };

  _getStyle = () => {
    return {
      width: this.props.width ? this.props.width : null,
      fontSize: this._getFontSoze()
    };
  };

  _getBackgroundColorClassName = () => {
    switch (this.props.backgroundColor) {
      case "white":
        return "UICoreInput-white";

      default:
        return "UICoreInput-default";
    }
  };

  _isNumeric = (n: any): boolean => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  _validateNumeric = (n: any): ?string => {
    if (this._isNumeric(n) || n === "") {
      // is a number, return null to indicate validation success.
      return null;
    } else {
      return "Input needs to be a number";
    }
  };

  _validateLetter = (input: string): ?string => {
    if (isStringAllLetters(input) || input === "") {
      return null;
    } else {
      return "Input can only contain letters";
    }
  };

  _isInputValid = (input: string): boolean => {
    let errorMessage = null;
    // Run custom validator first
    if (this.props.validator) {
      errorMessage = this.props.validator(input);
    }

    // If not custom validator provided, run property validator
    else if (this.props.type === "numeric") {
      errorMessage = this._validateNumeric(input);
    } else if (this.props.type === "letter") {
      errorMessage = this._validateLetter(input);
    }

    // Update error message
    this.setState({
      errorMessage: errorMessage
    });

    // If has error message, mark as invalid
    return errorMessage ? false : true;
  };

  _handleInputChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (this._isInputValid(e.target.value)) {
      this.props.onChange(e, e.target.value);
    } else {
      //invalid input, use prev input
      this.props.onChange(e, this.props.value ? this.props.value : "");
    }
  };

  _handleClick = (e: SyntheticEvent<>) => {
    if (this._inputNode && this.props.clickToSelect) {
      this._inputNode.select();
    }
  };

  render() {
    return (
      <UICoreBox
        position="relative"
        disabled={this.props.disabled}
        dangerous_style={{
          opacity: this.props.disabled ? 0.4 : 1
        }}
        borderColor={this.props.withBorder ? "lightGray" : ""}
        borderRadius={this.props.withBorder ? "lg" : ""}
      >
        <input
          onClick={this._handleClick}
          onCopy={this.props.onCopy}
          ref={ref => {
            this._inputNode = ref;
            this.props.getInputRef && this.props.getInputRef(ref);
          }}
          style={this._getStyle()}
          className={`UICoreInput ${this._getBackgroundColorClassName()}`}
          onChange={this._handleInputChange}
          value={this.props.value}
          onBlur={this.props.onBlur}
          onKeyUp={this.props.onKeyUp}
          placeholder={this.props.placeholder}
        />
        {this.state.errorMessage && (
          <UICoreBox
            dangerous_style={{ zIndex: 1 }}
            width={this.props.width}
            position="absolute"
            top="100%"
          >
            <UICoreText overflow="wrap" size="xxs" hexColor="#e11664">
              {this.state.errorMessage}
            </UICoreText>
          </UICoreBox>
        )}
      </UICoreBox>
    );
  }
}
export default UICoreInput;

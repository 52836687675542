//@flow
import {
  DELETE_QUESTION,
  ADD_DROPDOWN_CHOICE,
  REMOVE_CHOICE,
  ADD_CHOICE,
  EDIT_CHOICE,
  ADD_QUESTION_TYPE,
  UPDATE_QUESTION_TITLE,
  UPDATE_QUESTION_DESCRIPTION,
  SET_CURRENT_EDITING_QUESTION,
  SET_CURRENT_EDITING_THANKYOU_PAGE,
  UPDATE_FORM,
  DID_FETCH_FORM_DETAIL,
  SHOW_WARNING,
  HIDE_WARNING,
  Question_Changed,
  DID_SAVE_TO_BACKEND,
  UPDATE_QUESTION_CONFIG,
  UPDATE_STYLE,
  UPDATE_STYLES,
  UPDATE_STYLE_BACKGROUND,
  UPDATE_QUESTION_IMAGE,
  UPDATE_QUESTION_MEDIA,
  UPDATE_THANKYOU_PAGE,
  RESET_THANKYOU_PAGES_SHOW_SCORE,
  UPDATE_OUTCOME_PAGE,
  REORDER_PREVIEW_WINDOW,
  REORDER_MULTICHOICE_CHOICE,
  ADD_THANKYOU_PAGE,
  DELETE_THANKYOU_PAGE,
  UPDATE_JUMP_LOGIC,
  UPDATE_OUTCOME_DISPLAY_LOGIC,
  UPDATE_SCORE_CALCULATION,
  ADD_REDIRECT_URL,
  ADD_LOGO,
  UPDATE_CLOSE_FORM_SETTING,
  UPDATE_BUTTON_TEXT,
  ADD_GA_TRACKER_CODE,
  ADD_GTM_TRACKER_CODE,
  SET_SCORE_VARIABLE,
  TOGGLE_SET_SCORE_MODAL,
  type actionTypes,
  UPDATE_QUESTION_CONTENT,
  UPDATE_THANKYOU_PAGE_DISPLAY_LOGIC,
  UPDATE_DYNAMIC_PRICING,
  UPDATE_HIDDEN_FIELDS,
  UPDATE_HIDDEN_FIELD_VALUES,
  WILL_FETCH_FORM_DETAIL,
  COPY_QUESTION,
  COPY_THANKYOU_PAGE,
  UPDATE_OUTCOME_MAX_NUM
} from "./actions";
import QuestionTypes, { type questionType } from "../../QuestionTypes";
import update from "react-addons-update";
import { arrayMove } from "react-sortable-hoc";
import {
  type formIDType,
  type welcomePageType,
  type thankyouPageType
} from "../../WispformTypings";
import {
  DefaultButtonText,
  DefaultThankyouNote
} from "../../Form/Components/CompletePage";
import {
  replaceElementByAnchor,
  removeElemenent,
  toArray,
  isNonNull,
  isNull,
  insertElementAt,
  safeGet,
  toNumber,
  nonNullOrThrow
} from "../../../Library/Util";
import { isPaidUser } from "../../../Library/UserInfo";
import { getShareLink } from "../../Share";
import { type buttonConfigType } from "../../../Helper_HOC/WithConfigurableButtonText";
import { convertToNewThankYouPageDataStructureIfNecessary } from "../../../Library/thankyouPageHelper";
import { type formStyleType } from "../../../FlowTypes/wispformStyleTypes";
import {
  type hiddenFieldVariablesType,
  type hiddenFieldVariableValuesType
} from "../../../FlowTypes/hiddenFieldTypes";
import { getReformattedStyles } from "../../../Library/FormStyleDataStructureConversion";
import { getVisibleQuestionsNumber } from "./selectors";
import { type redirectParamConfigType } from "../../../FlowTypes/formRedirectTypes";
import { insertElementsByAnchor } from "../../../Library/Primitives/Array";
import uniqid from "uniqid";
import { type TGenericContentConfig } from "../../../Component/GenericFormContent/type";
export const thankyouPage = "thankyouPage";

export type QuestionsStateType = {|
  form_id: ?formIDType,
  last_edited_question: number | typeof thankyouPage,
  last_edited_thankyou_page: ?number,
  warning: boolean,
  isQuestionChanged: boolean,
  form_name: ?string,
  styles: formStyleType,
  welcomePage: ?welcomePageType,
  pages: {
    thankyouPages: {
      displayLogic: {},
      pages: Array<thankyouPageType>
    },
    outcomePage: {
      maxOutcomes: number,
      displayLogic: {},
      pageContentConfig: ?TGenericContentConfig
    }
  },
  questions: Array<questionType>,
  variables: {
    score: boolean
  },
  showSetScoreModal: boolean,
  configurations: {
    redirect_url: ?string,
    ga_tracker_code: ?string,
    close_form_setting: ?string,
    custom_close_form_text: ?string,
    gtm_tracker_code: ?string,
    logo: ?string,
    buttonText: ?buttonConfigType,
    hiddenFieldVariables?: hiddenFieldVariablesType,
    hiddenFieldVariableValues?: hiddenFieldVariableValuesType,
    redirectParam: ?redirectParamConfigType
  },
  responseCount: number,
  fetchingFormDetail: boolean
|};

let defaultState = {
  form_id: null,
  last_edited_question: 0,
  last_edited_thankyou_page: null,
  questions: [],
  welcomePage: null,
  pages: {
    thankyouPages: {
      displayLogic: {},
      pages: []
    },
    outcomePage: {
      maxOutcomes: 1,
      displayLogic: {},
      pageContentConfig: null
    }
  },
  warning: false,
  isQuestionChanged: false,
  form_name: null,
  //$FlowFixMe
  styles: new QuestionTypes().styles,
  variables: {
    score: false
  },
  configurations: {
    redirect_url: null,
    ga_tracker_code: null,
    gtm_tracker_code: null,
    close_form_setting: null,
    custom_close_form_text: null,
    logo: null,
    buttonText: null,
    redirectParam: null
  },
  showSetScoreModal: false,
  responseCount: 0,
  fetchingFormDetail: false
};

function get_insert_index(action, state) {
  //Welcome page will always be inserted in the begining of questionsthere
  //if there is no questions created, then insert at position 0
  //any other questions will be inserted right after curent editing question
  if (getVisibleQuestionsNumber(state) == 0) {
    return 0;
  } else if (action.question.type == "WelcomePage") {
    return 0;
  } else if (state.last_edited_question === "thankyouPage") {
    return getVisibleQuestionsNumber(state);
  } else {
    return state.last_edited_question + 1;
  }
}

function get_last_edited_index(action, state) {
  if (getVisibleQuestionsNumber(state) == 0) {
    return 0;
  } else if (action.question.type == "WelcomePage") {
    return 0;
  } else if (state.last_edited_question === "thankyouPage") {
    return getVisibleQuestionsNumber(state);
  } else {
    return state.last_edited_question + 1;
  }
}

export default function Questions(
  state: QuestionsStateType = defaultState,
  action: actionTypes
): QuestionsStateType {
  switch (action.type) {
    case ADD_QUESTION_TYPE:
      let insert_index = isNonNull(action.insertIndex)
        ? action.insertIndex
        : get_insert_index(action, state);
      let updatedLastEditedIndex = get_last_edited_index(action, state);
      //$FlowFixMe
      const question_types = new QuestionTypes().types;
      return update(state, {
        question_count: { $set: getVisibleQuestionsNumber(state) + 1 },
        last_edited_question: {
          $set: action.disableUpdatingLastEditingQuestionIndex
            ? state.last_edited_question
            : updatedLastEditedIndex
        },
        questions: {
          $set: [
            ...state.questions.slice(0, insert_index),
            {
              type: action.question.type,
              description: "",
              question_id: action.question.question_id,
              title: action.question.title || "",
              isRequired: false,
              resultPageWidth: 250,
              contents: question_types[action.question.type].defaultContent,
              config: {}
            },
            ...state.questions.slice(insert_index)
          ]
        }
      });

    case SET_SCORE_VARIABLE:
      return {
        ...state,
        variables: {
          ...state.variables,
          score: action.value
        }
      };

    case TOGGLE_SET_SCORE_MODAL:
      return {
        ...state,
        showSetScoreModal: action.show
      };

    case ADD_THANKYOU_PAGE:
      const currentThankyouPageNumber =
        (state.pages &&
          state.pages.thankyouPages &&
          state.pages.thankyouPages.pages &&
          state.pages.thankyouPages.pages.length) ||
        0;
      // we have a bug where reset showScore messed up the array structure
      // apply this to fix the array
      let pages_with_reset_score_fix = Array.isArray(
        state.pages.thankyouPages.pages
      )
        ? state.pages.thankyouPages.pages
        : [];
      return {
        ...state,
        last_edited_question: "thankyouPage",
        last_edited_thankyou_page: currentThankyouPageNumber,
        pages: {
          ...state.pages,
          thankyouPages: {
            ...state.pages.thankyouPages,
            pages: [
              ...pages_with_reset_score_fix,
              {
                buttonText: getDefaultThankyouPageButtonText(),
                redirectURL: getDefaultThankyouPageRedirectURL(),
                backgroundImage: null,
                thankyouNote: ""
              }
            ]
          }
        }
      };

    case UPDATE_OUTCOME_PAGE:
      return {
        ...state,
        pages: {
          ...state.pages,
          outcomePage: {
            ...state.pages.outcomePage,
            pageContentConfig: action.pageConfig
          }
        }
      };

    case UPDATE_THANKYOU_PAGE:
      return {
        ...state,
        pages: {
          ...state.pages,
          thankyouPages: {
            ...state.pages.thankyouPages,
            pages: action.pages
          }
        }
      };

    case RESET_THANKYOU_PAGES_SHOW_SCORE:
      return {
        ...state,
        pages: {
          ...state.pages,
          thankyouPages: {
            ...state.pages.thankyouPages,
            pages: toArray(state.pages.thankyouPages?.pages).map(page => {
              return {
                ...page,
                showScore: false
              };
            })
          }
        }
      };

    case UPDATE_THANKYOU_PAGE_DISPLAY_LOGIC:
      return {
        ...state,
        pages: {
          ...state.pages,
          thankyouPages: {
            ...state.pages.thankyouPages,
            displayLogic: action.updatedDisplayLogic
          }
        }
      };

    case UPDATE_DYNAMIC_PRICING:
      return {
        ...state,
        questions: replaceElementByAnchor(
          state.questions,
          q => q.question_id === action.questionID,
          q => {
            return {
              ...q,
              config: {
                ...q.config,
                dynamicPricing: action.updatedLogic
              }
            };
          }
        )
      };

    case COPY_QUESTION:
      return {
        ...state,
        questions: insertElementAt(
          state.questions,
          {
            ...state.questions[action.questionToCopy],
            question_id: uniqid.time()
          },
          action.questionToCopy
        ),
        last_edited_question: action.questionToCopy + 1
      };

    case COPY_THANKYOU_PAGE:
      const insertIndex: number = action.thankyouPageToCopy;
      const thankyouPages = toArray(
        safeGet(_ => state.pages.thankyouPages.pages)
      );
      return {
        ...state,
        pages: {
          ...state.pages,
          thankyouPages: {
            ...state.pages.thankyouPages,
            pages: insertElementAt(
              thankyouPages,
              {
                ...thankyouPages[insertIndex]
              },
              action.thankyouPageToCopy
            )
          }
        },
        last_edited_question: thankyouPage,
        last_edited_thankyou_page: insertIndex + 1
      };

    case DELETE_THANKYOU_PAGE:
      const updatedFormIndex = updateLastEditedQuestionOrThankyouPage(
        state.last_edited_question,
        Number(state.last_edited_thankyou_page),
        getVisibleQuestionsNumber(state)
      );
      return {
        ...state,
        pages: {
          ...state.pages,
          thankyouPages: {
            ...state.pages.thankyouPages,
            pages: removeElemenent(
              state.pages.thankyouPages.pages,
              action.index
            )
          }
        },
        last_edited_question: updatedFormIndex.lastEditedQuestion,
        last_edited_thankyou_page: updatedFormIndex.lastEditedThankyouPage
      };
    case UPDATE_OUTCOME_DISPLAY_LOGIC:
      return {
        ...state,
        questions: replaceElementByAnchor(
          state.questions,
          q => q.question_id === action.questionID,
          q => {
            return {
              ...q,
              outcomeDisplayLogic: action.updatedOutcomeDisplayLogic
            };
          }
        )
      };

    case UPDATE_JUMP_LOGIC:
      return {
        ...state,
        questions: replaceElementByAnchor(
          state.questions,
          q => q.question_id === action.questionID,
          q => {
            return {
              ...q,
              jumpLogic: action.updatedJumpLogic
            };
          }
        )
      };

    case UPDATE_SCORE_CALCULATION:
      return {
        ...state,
        questions: replaceElementByAnchor(
          state.questions,
          q => q.question_id === action.questionID,
          q => {
            return {
              ...q,
              scoreCalculations: action.updatedScoreCalculation
            };
          }
        )
      };

    case ADD_REDIRECT_URL:
      return {
        ...state,
        configurations: {
          ...state.configurations,
          redirect_url: action.url
        }
      };

    case ADD_LOGO:
      return {
        ...state,
        configurations: {
          ...state.configurations,
          logo: action.url
        }
      };

    case UPDATE_CLOSE_FORM_SETTING:
      return {
        ...state,
        configurations: {
          ...state.configurations,
          close_form_setting: action.close_form_setting,
          custom_close_form_text: action.custom_close_form_text
        }
      };

    case UPDATE_BUTTON_TEXT: {
      return {
        ...state,
        configurations: {
          ...state.configurations,
          buttonText: action.updatedButtonText
        }
      };
    }

    case UPDATE_HIDDEN_FIELDS: {
      return {
        ...state,
        configurations: {
          ...state.configurations,
          hiddenFieldVariables: action.updatedHiddenFeilds
        }
      };
    }

    case UPDATE_HIDDEN_FIELD_VALUES: {
      return {
        ...state,
        configurations: {
          ...state.configurations,
          hiddenFieldVariableValues: action.updatedHiddenFieldValues
        }
      };
    }

    case ADD_GA_TRACKER_CODE:
      return {
        ...state,
        configurations: {
          ...state.configurations,
          ga_tracker_code: action.code
        }
      };

    case ADD_GTM_TRACKER_CODE:
      return {
        ...state,
        configurations: {
          ...state.configurations,
          gtm_tracker_code: action.code
        }
      };

    case SET_CURRENT_EDITING_QUESTION:
      return update(state, {
        last_edited_question: { $set: action.questionNumber }
      });

    case SET_CURRENT_EDITING_THANKYOU_PAGE:
      return update(state, {
        last_edited_thankyou_page: { $set: action.thankyouPageNumber }
      });

    case UPDATE_STYLE:
      return update(state, {
        styles: {
          [action.style]: { $set: action.value }
        }
      });

    case UPDATE_STYLES:
      return {
        ...state,
        styles: {
          ...state.styles,
          ...action.styles
        }
      };

    case UPDATE_STYLE_BACKGROUND:
      return {
        ...state,
        styles: {
          ...state.styles,
          background: action.background
        }
      };

    case REORDER_PREVIEW_WINDOW:
      return {
        ...state,
        questions: arrayMove(
          state.questions,
          action.currentIndex,
          action.newIndex
        )
      };

    case REORDER_MULTICHOICE_CHOICE:
      return update(state, {
        questions: {
          [action.pageNumber]: {
            contents: {
              $set: arrayMove(
                state.questions[action.pageNumber].contents,
                action.currentIndex,
                action.newIndex
              )
            }
          }
        }
      });

    case UPDATE_QUESTION_TITLE:
      return update(state, {
        questions: {
          [action.pageNumber]: {
            title: { $set: action.title }
          }
        }
      });

    case UPDATE_QUESTION_IMAGE:
      return update(state, {
        questions: {
          [action.pageNumber]: {
            image: { $set: action.imageURL }
          }
        }
      });

    case UPDATE_QUESTION_MEDIA:
      return update(state, {
        questions: {
          [action.index]: {
            media: { $set: action.media },
            image: { $set: null }
          }
        }
      });

    case UPDATE_QUESTION_CONFIG:
      return update(state, {
        questions: {
          [action.page_number]: {
            config: {
              [action.config_name]: { $set: action.value }
            }
          }
        }
      });

    case UPDATE_QUESTION_DESCRIPTION:
      return update(state, {
        questions: {
          [action.pageNumber]: {
            description: { $set: action.description }
          }
        }
      });

    case EDIT_CHOICE:
      return update(state, {
        questions: {
          [action.pageNumber]: {
            contents: {
              $set: [
                /* eslint-disable */
                ...state.questions[action.pageNumber].contents.slice(
                  0,
                  action.Choice_Index
                ),
                /* eslint-enable*/
                action.Choice_Content,
                /* eslint-disable */
                ...state.questions[action.pageNumber].contents.slice(
                  action.Choice_Index + 1
                )
              ]
            }
            /* eslint-enable */
          }
        }
      });

    case UPDATE_QUESTION_CONTENT:
      return {
        ...state,
        questions: [
          ...state.questions.slice(0, action.pageNumber),
          {
            ...state.questions[action.pageNumber],
            contents: action.contents
          },
          ...state.questions.slice(action.pageNumber + 1)
        ]
      };

    case ADD_CHOICE:
      if (action.choiceIndex !== null) {
        return update(state, {
          questions: {
            [action.pageNumber]: {
              contents: {
                $set: [
                  /* eslint-disable */
                  ...state.questions[action.pageNumber].contents.slice(
                    0,
                    action.choiceIndex + 1
                  ),
                  "",
                  ...state.questions[action.pageNumber].contents.slice(
                    action.choiceIndex + 1
                  )
                ]
              }
            }
          }
        });
      } else {
        return update(state, {
          questions: {
            [action.pageNumber]: {
              contents: {
                $set: [
                  ...state.questions[action.pageNumber].contents.slice(0),
                  ""
                ]
              }
            }
          }
        });
      }

    case REMOVE_CHOICE:
      if (state.questions[action.pageNumber].contents.length === 1) {
        return update(state, {
          questions: {
            [action.pageNumber]: {
              contents: {
                $set: [""]
              }
              /* eslint-enable */
            }
          }
        });
      } else {
        return update(state, {
          questions: {
            [action.pageNumber]: {
              contents: {
                $set: [
                  /* eslint-disable */
                  ...state.questions[action.pageNumber].contents.slice(
                    0,
                    action.Choice_Index
                  ),
                  ...state.questions[action.pageNumber].contents.slice(
                    action.Choice_Index + 1
                  )
                ]
              }
              /* eslint-enable */
            }
          }
        });
      }

    case ADD_DROPDOWN_CHOICE:
      return update(state, {
        questions: {
          [action.pageNumber]: {
            contents: { $set: action.choice_content.split("\n") }
          }
        }
      });

    case DELETE_QUESTION:
      return {
        ...state,
        questions: get_deleted_question_array(
          state.questions,
          action.pageNumber
        ),
        last_edited_question: action.shouldUpdateLastEditedIndex
          ? updateLastEditedQuestionOrThankyouPage(
              state.last_edited_question,
              Number(state.last_edited_thankyou_page),
              getVisibleQuestionsNumber(state)
            ).lastEditedQuestion
          : state.last_edited_question
      };

    case UPDATE_FORM:
      return update(state, {});

    case WILL_FETCH_FORM_DETAIL:
      return {
        ...defaultState,
        fetchingFormDetail: true
      };

    case DID_FETCH_FORM_DETAIL:
      return {
        ...state,
        form_id: action.form.form_id,
        last_edited_question: action.form.last_edited_question,
        questions: action.form.questions || [],
        form_name: action.form_name,
        styles: getReformattedStyles(action.form.styles) || defaultState.styles,
        pages:
          convertToNewThankYouPageDataStructureIfNecessary(action.form.pages) ||
          defaultState.pages,
        variables: action.form.variables,
        configurations: action.form.configurations,
        responseCount: action.responseCount,
        fetchingFormDetail: false
      };

    case SHOW_WARNING:
      return update(state, {
        warning: { $set: true }
      });

    case HIDE_WARNING:
      return update(state, {
        warning: { $set: false }
      });

    case Question_Changed:
      return update(state, {
        isQuestionChanged: { $set: true }
      });

    case DID_SAVE_TO_BACKEND:
      return update(state, {
        isQuestionChanged: { $set: false }
      });

    case UPDATE_OUTCOME_MAX_NUM:
      return {
        ...state,
        pages: {
          ...state.pages,
          outcomePage: {
            ...state.pages.outcomePage,
            maxOutcomes: action.maxNum
          }
        }
      };
    default:
      return state;
  }
}

function get_deleted_question_count(question_count) {
  return question_count <= 0 ? 0 : question_count - 1;
}

function get_deleted_question_array(questions, pageNumber) {
  let deleted_question_array = [
    ...questions.slice(0, pageNumber),
    ...questions.slice(pageNumber + 1)
  ];

  return deleted_question_array.length > 0 ? deleted_question_array : [];
}

function getDefaultThankyouPageButtonText(): string {
  return "Start Over";
}

function getDefaultThankyouPageRedirectURL(): string {
  return getShareLink();
}

function updateLastEditedQuestionOrThankyouPage(
  lastEditedQuestion,
  lastEditedThankyouPage: number,
  totalNumberOfQuestionsBeforeDeleting
) {
  const ArrayOffset = 1;
  if (lastEditedQuestion === thankyouPage) {
    if (lastEditedThankyouPage && lastEditedThankyouPage > 0) {
      return {
        lastEditedQuestion: thankyouPage,
        lastEditedThankyouPage: lastEditedThankyouPage - 1
      };
    } else {
      // last edited is the only thankyou page
      return {
        lastEditedQuestion: totalNumberOfQuestionsBeforeDeleting - ArrayOffset,
        lastEditedThankyouPage: null
      };
    }
  } else {
    // last edited is a question
    if (typeof lastEditedQuestion === "number" && lastEditedQuestion > 0) {
      return {
        lastEditedQuestion: Number(lastEditedQuestion) - 1,
        lastEditedThankyouPage: null
      };
    } else {
      return {
        lastEditedQuestion: 0,
        lastEditedThankyouPage: null
      };
    }
  }
}

//@flow

/**
 * AppWrapper is the base component for the entire application, both wispform and wisptable
 * You can put logic & component that shared accross the app here
 * A good use case is to put modal and notification UI here, so they can be triggered and  displayed acros the app
 */
import * as React from "react";
import Notification, { type notificationType } from "../Notification";
import { connect } from "react-redux";
import { getNotifications } from "../../Pages/WispTable/State/NotificationState/selectors";
import { getDialog } from "../../Pages/WispTable/State/DialogState/selectors";
import { toArray } from "../../Library/Util";
import "./style.css";
import { UICoreBox } from "../UICore";
import UICoreDialog, { type Props as dialogType } from "../UICore/UICoreDialog";
import UpgradeModal from "../UpgradeModal";
import { getUpgradeModalProps } from "../../Pages/FormBuilderPage/States/AccountUpgradeModal/selectors";
import { type accountUpgradeModalstateType } from "../../Pages/FormBuilderPage/States/AccountUpgradeModal/reducers";
import { closeAccountUpgradeModal } from "../../Pages/FormBuilderPage/States/AccountUpgradeModal/actions";
import initScreenRecording from "../../Library/initScreenRecording";
import { initGoogleTagManagerWithWispformID } from "../../Library/GoogleTagManager";
import { isFormPage } from "../../Library/URL";
type Props = {|
  children: React.Node,
  notifications: Array<{|
    title: string,
    description: string,
    notificationType: notificationType,
    id: string
  |}>,
  dialog: dialogType,
  accountUpgradeModal: accountUpgradeModalstateType,
  closeAccountUpgradeModal: () => void
|};
type State = {||};
class AppWrapper extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    // this._initScreenRecording();
    initGoogleTagManagerWithWispformID();
  }

  _initScreenRecording = () => {
    try {
      if (window.location.hostname === "localhost") {
        return;
      }
      const pathName = window.location.pathname.split("/")[1];
      if (["workspace", "setting", "form"].includes(pathName)) {
        initScreenRecording();
      }
    } catch (error) {}
  };

  _showNotifications = () => {
    return (
      <UICoreBox
        className="AppWrapper-Notifications"
        position="fixed"
        top={"0px"}
        right={"0px"}
        direction="column"
      >
        {toArray(this.props.notifications).map(notification => {
          return (
            <UICoreBox paddingTop="xm" paddingBottom="xm">
              <Notification
                title={notification.title}
                description={notification.description}
                notificationType={notification.notificationType}
                id={notification.id}
                actionName={notification.actionName}
                onActionClick={notification.onActionClick}
              />
            </UICoreBox>
          );
        })}
      </UICoreBox>
    );
  };

  _showDialog = () => {
    if (this.props.dialog) {
      return <UICoreDialog {...this.props.dialog} />;
    } else return null;
  };

  _renderUpgradeModal = () => {
    if (this.props.accountUpgradeModal.showAccountUpgradeModal) {
      return (
        <UpgradeModal
          onModalDismiss={this.props.closeAccountUpgradeModal}
          featureName={this.props.accountUpgradeModal.featureName}
          onTryButtonClick={this.props.accountUpgradeModal.featureAction}
        />
      );
    } else return null;
  };

  render() {
    return (
      <div>
        {this._showNotifications()}
        {this._showDialog()}
        {this._renderUpgradeModal()}
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    notifications: getNotifications(state.Notifications),
    dialog: getDialog(state.Dialog),
    accountUpgradeModal: getUpgradeModalProps(state.AccountUpgradeModal)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeAccountUpgradeModal: () => {
      dispatch(closeAccountUpgradeModal());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
